const adminLogin = ({ email, password }) => {
  const raw = JSON.stringify({ email: email, password: password });
  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };
  return fetch(`admin_auth/sign_in`, requestOptions)
    .then((user) => {
      return { user: user };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const setHomeProjects = (projectsIdsArray) => {
  const raw = JSON.stringify({ home_page_projects: projectsIdsArray });
  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };
  return fetch(`admin/projects/home_page`, requestOptions)
    .then((projects) => {
      return { projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const adminAddCategory = (formData) => {
  // const raw = JSON.stringify({ category: value });
  // const requestOptions = {
  //   method: 'POST',
  //   body: raw,
  //   redirect: 'follow',
  // };
  const requestOptions = {
    method: 'POST',
    body: formData,
    redirect: 'follow',
  };

  return fetch(`category`, requestOptions)
    .then((projects) => {
      return { projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const declineProject = (id, message) => {
  const raw = JSON.stringify({ message });
  const requestOptions = {
    method: 'PUT',
    body: raw,
    redirect: 'follow',
  };
  return fetch(
    `projects/${id}/decline_project${message ? `?message=${message}` : ''}`,
    requestOptions
  )
    .then((project) => {
      return { project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const acceptProject = (id) => {
  const requestOptions = {
    method: 'PUT',
    body: '',
    redirect: 'follow',
  };
  return fetch(`projects/${id}/accept_project`, requestOptions)
    .then((project) => {
      return { project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const showProjectById = (id) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`admin/projects/${id}`, requestOptions)
    .then((project) => {
      return { project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const updateProjectbyId = (id, values) => {
  const requestOptions = {
    method: 'PATCH',
    body: values,
  };

  return fetch(`projects_edit?id=${id}`, requestOptions)
    .then((project) => {
      return { project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const startProjectById = (id, values) => {
  const requestOptions = {
    method: 'PATCH',
    body: values,
  };

  return fetch(`project_start?id=${id}`, requestOptions)
    .then((project) => {
      return { project };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getAllProjects = (perPage, page, status, filter) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(
    `admin/projects?per_page=${perPage}&page=${page}${
      status ? `&status=${status}` : ''
    }${filter ? `&filter=${filter}` : ''}`,
    requestOptions
  )
    .then((projects) => {
      return { projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getAllDonationList = (page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`admin/all_projects?page=${page}`, requestOptions)
    .then((projects) => {
      return { projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};
const getEnabled = (isEnabled) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`admin/enable_paypal?isEnabled=${isEnabled}`, requestOptions)
    .then((res) => {
      return { res };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getRecurring = (isEnabled) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`admin/enable_all_recurring?isEnabled=${isEnabled}`, requestOptions)
    .then((res) => {
      return { res };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};
const getAllAdminProjectsCsv = (filter, status) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(
    `admin/admin_csv?${filter ? `&filter=${filter}` : ''}${
      status ? `&status=${status}` : ''
    }`,
    requestOptions
  )
    .then((projects) => {
      return { projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getDonationDetailService = (id, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`admin/${id}/donations?page=${page}`, requestOptions)
    .then((projects) => {
      return { projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getAllSelectedProjects = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`projects/home_page`, requestOptions)
    .then((projects) => {
      return { projects };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const updateDonationDataService = (data) => {
  // const RAW = { donation_id: id };
  const requestOptions = {
    method: 'POST',
    body: data,
  };
  return fetch(`update/donation`, requestOptions)
    .then((res) => {
      return { res };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const createDonationDataService = (data) => {
  // const RAW = { donation_id: id };
  const requestOptions = {
    method: 'POST',
    body: data,
  };
  return fetch('donation_create', requestOptions)
    .then((res) => {
      return { res };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const addFundingDays = async (values) => {
  const requestOptions = {
    method: 'PUT',
    redirect: 'follow',
  };
  try {
    const response = await fetch(
      `admin/projects/add_funding_days?project_url=${values.project_url}&days=${values.days}`,
      requestOptions
    );
    return response;
  } catch (error) {
    return await Promise.reject({ errors: error });
  }
};

const createDraftEmail = async (values) => {
  const raw = JSON.stringify({
    project: {
      title: values.title,
      goal: values.goal,
      category_id: values.category_id,
      city: values.city,
    },
  });
  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };
  try {
    const response = await fetch(
      `admin/projects/create_draft?user_email=${values.user_email}`,
      requestOptions
    );
    return response;
  } catch (error) {
    return await Promise.reject({ errors: error });
  }
};

const getAllBookedGoodies = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch('reward_order', requestOptions)
    .then((goodies) => {
      return { goodies };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const logout = () => {
  return () => {
    localStorage.removeItem('user');
    localStorage.removeItem('user-type');
    localStorage.removeItem('access-token');
    localStorage.removeItem('client');
    localStorage.removeItem('uid');
    return { user: null };
  };
};

const getAdminRecurringPayments = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/admin/recurring_donations`, requestOptions)
    .then((donations) => {
      return { donations };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const getParticipants = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/admin/participants`, requestOptions)
    .then((participants) => {
      return participants;
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const adminRecurringPaymentsCanceled = (id) => {
  const RAW = { donation_id: id };
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: JSON.stringify(RAW),
  };
  return fetch(`cancel_donations`, requestOptions)
    .then((res) => {
      return { res };
    })
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};
const getCategoryBasedProjects = (idOrName, isName, type = 'projects') => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  let url;
  if (isName) {
    url = `/category/${type}?name=${idOrName}`;
  } else {
    url = `category/${type}?category_id=${idOrName}`;
  }
  return fetch(`${url}`, requestOptions)
    .then((result) => result)
    .catch((error) => {
      return Promise.reject({ errors: error });
    });
};

const toggleCategory = (id, value, name) => {
  return fetch(`category/update?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      visible: value,
      name,
    }),
  });
};

const updateCategory = (id, body) => {
  return fetch(`category/update?id=${id}`, {
    method: 'POST',
    body,
  });
};

const deleteCategory = (id) => {
  return fetch(`category/${id}`, {
    method: 'DELETE',
  });
};

const saveEditCategory = (id, name) => {
  return fetch(`category/update?id=${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ category: { name } }),
  });
};

const deleteDonation = (id) =>
  fetch(`donations?id=${id}`, { method: 'DELETE' });

const getAllDonations = (id) =>
  fetch(`csv_donations?project_id=${id}`, { method: 'GET' });

const adminServices = {
  adminLogin,
  toggleCategory,
  deleteCategory,
  saveEditCategory,
  acceptProject,
  declineProject,
  setHomeProjects,
  getAllProjects,
  showProjectById,
  addFundingDays,
  createDraftEmail,
  updateProjectbyId,
  startProjectById,
  getAllBookedGoodies,
  logout,
  getAdminRecurringPayments,
  getParticipants,
  adminRecurringPaymentsCanceled,
  getAllSelectedProjects,
  getAllDonationList,
  getDonationDetailService,
  updateDonationDataService,
  getAllAdminProjectsCsv,
  adminAddCategory,
  getCategoryBasedProjects,
  updateCategory,
  getEnabled,
  getRecurring,
  deleteDonation,
  getAllDonations,
  createDonationDataService,
};

export default adminServices;
