import React from 'react';
import { Card } from 'components';
import styled from '@emotion/styled';
import { Colors, device } from 'utils';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ExternalLink from 'components/ExternalLink/ExternalLink';

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  .card {
    width: 32%;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;
    @media ${device.allMobile} {
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
  }
`;

const SuccessStoriesWrapper = styled.div`
  margin: 60px auto;
  text-align: center;
  h1 {
    text-align: left;
    font-size: 30px;
    color: ${Colors.primaryColor};
    @media ${device.allMobile} {
      text-align: center;
      font-size: 24px;
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
  }
  & > a {
    display: inline-block;
    text-decoration: none;
    margin: 20px auto 0;
    color: ${Colors.secondaryColor};
    border-bottom: 2px solid ${Colors.transparent};
    &:hover {
      border-bottom: 2px solid ${Colors.secondaryColor};
    }
  }
`;
const cardObj1 = {
  photo: '',
  category: 'Learning',
  from: 'EN',
  name: 'learning homeless people',
  description: `the acquisition of knowledge or skills through experience, study, or
  by being taught. the acquisition of knowledge or skills through
  experience, study, or by being taught.`,
  target: 10000,
  fundings: 1000000.14,
  days: 14,
};
const cardObj2 = {
  photo: '',
  category: 'Learning',
  from: 'DN',
  name: 'learning Quran',
  description: `the acquisition of knowledge or skills through experience, study, or
  by being taught. the acquisition of knowledge or skills through
  experience, study, or by being taught.`,
  target: 100000,
  fundings: 67400.51,
  days: 5,
};
const cardObj3 = {
  photo: '',
  category: 'Sadkaa',
  from: 'EN',
  name: 'Sadkaa',
  description: `the acquisition of knowledge or skills through experience, study, or
  by being taught. the acquisition of knowledge or skills through
  experience, study, or by being taught.`,
  target: 100000,
  fundings: 10000,
  days: 7,
};

export default function SuccessStories(props) {
  return (
    <SuccessStoriesWrapper>
      <h1>{props.title}</h1>
      <CardsWrapper>
        <Card project={cardObj1} />
        <Card project={cardObj3} />
        <Card project={cardObj2} />
      </CardsWrapper>
      <ExternalLink href="/projects">Mehr anzeigen</ExternalLink>
    </SuccessStoriesWrapper>
  );
}

SuccessStories.propTypes = {
  title: PropTypes.string.isRequired,
};
