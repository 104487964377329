import React, { Component } from 'react';
import { Button, Form, Tooltip } from 'antd';
import {
  PrimaryButton,
  H1,
  numberWithCommas,
  device,
  Colors,
  formValidateMessages,
  Modal,
} from 'utils';
import TipSelector from './TipSelector';
import { ChooseRewards, Danke } from '.';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input-field';
import styled from '@emotion/styled';
import DropDown from './DropDown';
import { connect } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import '../../../utils/Payment';
const FormItem = Form.Item;

const DonationWrapper = styled.div`
.hide-Rhythmus {
      display: none;
      }
  .currency-input {
    margin: 0;
    input,
    button {
      padding-right: 20px !important;
    }
    &::after {
      width: 20px;
      border: none;
      background: none;
      line-height: 34px;
      @media ${device.tablet} {
        line-height: 25px;
      }
      @media ${device.allMobile} {
        line-height: 25px;
      }
    }
  }
  .donation-input-failed {
    label {
      color: ${(props) =>
        props.donationLessThenFive && `${Colors.red}`};
    }
  }
  .primary-button {
    @media ${device.laptop} {
      padding: 15px 14px;
    }
    @media ${device.allMobile} {
      padding: 5px 7px;
      font-size: 14px;
      border-radius: 5px;
      display: flex !important;
      flex-direction: row !important;
    }
    @media ${device.tablet} {
      padding: 5px 7px;
      font-size: 14px;
      border-radius: 5px;
    }
  }
  .feriwill-button {
    font-weight: bold;
    color: black;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 24px;
    padding: 10px;
    text-align: right;
    max-width: 100px;
    background: none;
    width: 100%;
    @media ${device.allMobile} {
      font-size: 16px;
    }
  }
  .feriwill-button:hover {
    color: #95bd51;
    border-color: #95bd51;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    font-size: 14px;
    @media ${device.tablet} {
      font-size: 12px;
    }
  }
  .small-font {
    font-size: 0.9rem;
    @media ${device.allMobile} {
      display: block;
    }
  }
  .tip-btn {
    &:hover {
      background: #95bd51;
      color: #000;
    }
  }
  .text-setting {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 425px) {
    .donation-input .ant-form-item-label {
      font-size: 14px !important;
    }
    .text-setting {
      display: flex;
      // align-items: center;
      // justify-content: center;
      // width: 100% !important;
    }
    .label-blk {
      .ant-form-item-label {
        // width: 100% !important;
        // max-width: 100% !important;
        label {
          width: 100% !important;
        }
      }
      
      .ant-form-item-control {
        width: 100% !important;
        max-width: 100% !important;
        // text-align: center !important;
        @media (max-width: 575px) {
          flex: 0 0 50% !important;
        }
      }
    }
    .donation-input-failedd {
      display: flex !important;
      flex-direction: column;
      width: 100%;
    }
  }
  .donation-override .ant-form-item-control {
    @media (min-width: 1201px) {
      max-width: 250px !important;
    }
    @media (max-width: 1200px) and (min-width: 1000px) {
      max-width: 220px !important;
    }
    @media (max-width: 999px) and (min-width: 950px) {
      max-width: 120px !important;
    }
    @media (max-width: 820px) and (min-width: 767px) {
      max-width: 96px !important;
    }
  }
  // Total sum
  .gesamtsumme-container {
    width: 100%;
    padding-bottom: 16px;
  }

  .gesamtsumme-title {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 12px;
  }

  .gesamtsumme {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .summary-item {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
  }

  .summary-label {
    font-weight: bold;
  }

  .summary-value {
    font-weight: normal;
  }

  .no-line-breaks {
    white-space: nowrap;
  }

  .summary-divider {
    border: none;
    height: 1px;
    background: #ccc;
    margin: 12px 0;
  }

  .summary-total {
    font-size: 18px;
    font-weight: bold;
  }

  .ant-row, .ant-form-item-row {
    max-width: 100% !important; /* Ensures it doesn't overflow */
  }

  /* Make label appear above input */
  .new-donation-input {
    display: flex;
    flex-direction: column;
    label {
      font-size: 18px !important;
    }
    font-weight: bold;
  }

  /* Container to hold donation input */
  .new-currency-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: ${(props) =>
      props.donationLessThenFive
        ? `1px solid ${Colors.red}`
        : `1px solid #DCDCDC`} !important;
    border-radius: 10px;
    padding: 8px;
    max-width: 100% !important;
    min-height: 64px;
    height: 64px;
  }

  /* Input field */
  .new-currency-input {
    flex: 1;
    border: none;
    outline: none;
    font-size: 32px;
    text-align: left;
    font-weight: bold;
    padding-left: 20px;
    min-width: 0;
    max-width: 100%;
    max-height: 58px;
  }

  /* Currency symbol (€) */
  .new-currency-symbol {
    font-size: 32px;
    font-weight: normal !important;
    color: #6E6E6E;
    padding-right: 20px;
    flex-shrink: 0;
  }

  .freiwilliges-input-wrapper {
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }
`;
// const DonationLessThenFiveError = styled.p`
//   color: ${Colors.red};
//   margin-top: -24px;
// `;
class Donation extends Component {
  static propTypes = {
    showPaymentForm: PropTypes.bool.isRequired,
    donation: PropTypes.any.isRequired,
    freiwilliges: PropTypes.any.isRequired,
    showOption: PropTypes.bool.isRequired,
    otherOption: PropTypes.bool.isRequired,
    originalDonation: PropTypes.number.isRequired,
    setDonationState: PropTypes.func.isRequired,
    setCurrentViewState: PropTypes.func.isRequired,
    setShowOptionState: PropTypes.func.isRequired,
    setOtherOptionState: PropTypes.func.isRequired,
    setFreiwilligesState: PropTypes.func.isRequired,
    setShowPaymentFormState: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    originalRewardId: PropTypes.number.isRequired,
    setAllRewards: PropTypes.func.isRequired,
    setSelectedRewards: PropTypes.func.isRequired,
    setAddressRequired: PropTypes.func.isRequired,
    allRewards: PropTypes.any.isRequired,
    selectedRewards: PropTypes.any.isRequired,
    formRef: PropTypes.object.isRequired,
    handleCLientSecret: PropTypes.func.isRequired,
    handelShowModal: PropTypes.any.isRequired,
    isTicketable: PropTypes.bool.isRequired,
    project_name: PropTypes.string
  };
  state = {
    selectedOption: null,
    donationLessThenFive: false,
    canChangeFeriwilliges: true,
    lastDonation: 0,
    selectedKey: 0,
    showGoodiesLimitModal: false,
    goodiesWithLimitReached: [],
  };

  numberToString = (value) => {
    return parseFloat(value).toFixed(2);
  };

  handleSetSelectedKey = (key) => {
    this.setState({ selectedKey: key });
    this.props.saveKey(key);
  };

  mapDonatiosToValue = (value) => {
    let mappedValue, donationValue;

    if (value && typeof value === 'string' && value.includes(',')) {
      const splitNumber = value.split(',');
      const decimalTens = splitNumber[1]
        ? parseFloat(splitNumber[1][0]) / 10
        : 0;
      const decimalHundreds =
        splitNumber[1] && splitNumber[1].length > 1
          ? parseFloat(splitNumber[1][1]) / 100
          : 0;
      const decimalNumber = decimalTens + decimalHundreds;
      donationValue = parseFloat(splitNumber[0]) + decimalNumber;
    } else {
      donationValue = value ? parseFloat(Number(value)) : 0;
    }
    switch (true) {
      case donationValue < 7:
        mappedValue = 1;
        break;
      case donationValue < 10:
        mappedValue = 1.5;
        break;
      case donationValue < 12:
        mappedValue = 2;
        break;
      case donationValue < 15:
        mappedValue = 2.5;
        break;
      case donationValue < 17:
        mappedValue = 3;
        break;
      case donationValue < 20:
        mappedValue = 3.5;
        break;
      case donationValue < 22:
        mappedValue = 4;
        break;
      case donationValue < 25:
        mappedValue = 4.5;
        break;
      case donationValue < 27:
        mappedValue = 5;
        break;
      case donationValue < 30:
        mappedValue = 5.5;
        break;
      case donationValue < 32:
        mappedValue = 6;
        break;
      case donationValue < 35:
        mappedValue = 6.5;
        break;
      case donationValue < 37:
        mappedValue = 7;
        break;
      case donationValue < 40:
        mappedValue = 7.5;
        break;
      case donationValue < 50:
        mappedValue = 8;
        break;
      case donationValue < 60:
        mappedValue = 9;
        break;
      case donationValue < 70:
        mappedValue = 10;
        break;
      case donationValue < 80:
        mappedValue = 11;
        break;
      case donationValue < 90:
        mappedValue = 12;
        break;
      case donationValue < 100:
        mappedValue = 13;
        break;
      case donationValue < 110:
        mappedValue = 14;
        break;
      case donationValue < 120:
        mappedValue = 15;
        break;
      case donationValue < 130:
        mappedValue = 16;
        break;
      case donationValue < 140:
        mappedValue = 17;
        break;
      case donationValue < 150:
        mappedValue = 18;
        break;
      case donationValue < 160:
        mappedValue = 19;
        break;
      case donationValue < 170:
        mappedValue = 20;
        break;
      case donationValue < 180:
        mappedValue = 21;
        break;
      case donationValue < 190:
        mappedValue = 22;
        break;
      case donationValue < 231:
        mappedValue = 23;
        break;

      default:
        mappedValue = donationValue / 10;
        break;
    }

    return mappedValue;
  };

  handelCloseGoodiesLimitModal = () => {
    this.setState({ showGoodiesLimitModal: false });
    document.body.style.overflowY = 'unset';
  };

  componentDidMount() {
    const { donation, setFreiwilligesState } = this.props;
    setFreiwilligesState(
      this.numberToString(this.mapDonatiosToValue(donation))
    );
  }

  selectedRewardsPrice = () => {
    const { selectedRewards } = this.props;
    let totalPrice = 0;

    selectedRewards.forEach((reward) => {
      totalPrice += Number(reward.price);
    });

    return totalPrice;
  };

  // Check if the Selected Rewards were changed
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedRewards !== this.props.selectedRewards) {
      const { donation, setFreiwilligesState, selectedRewards } = this.props;
      const totalDonation = Number(donation) + this.selectedRewardsPrice();
      // Set the Tips from the Rewards and Donation
      setFreiwilligesState(
        this.numberToString(this.mapDonatiosToValue(totalDonation))
      );
      // Check if the total has passed 5
      if (totalDonation < 5) {
        this.setState({ donationLessThenFive: true });
      } else {
        this.setState({ donationLessThenFive: false });
      }
      // Revalidate the form
      this.props.formRef.current.validateFields();
      this.handleCheckRewardLimit();
    }
  }

  handleCheckRewardLimit = () => {
    const { selectedRewards } = this.props;
    const limitReachedGoodies = selectedRewards?.filter(
      (goodie) => goodie?.max_order_count === goodie?.ordered_reward_count
    );
    if (limitReachedGoodies.length > 0) {
      this.setState({ showGoodiesLimitModal: true });
      this.setState({ goodiesWithLimitReached: limitReachedGoodies });
    }
  };
  render() {
    const {
      donation,
      freiwilliges,
      showOption,
      otherOption,
      showPaymentForm,
      setDonationState,
      setCurrentViewState,
      setShowOptionState,
      setOtherOptionState,
      setFreiwilligesState,
      setShowPaymentFormState,
      handelShowModal,
      projectId,
      originalRewardId,
      isTicketable,
      selectedRewards,
      recurringCheck,
    } = this.props;
    const { showGoodiesLimitModal, goodiesWithLimitReached } = this.state;
    // Total Sum
    const totalPriceSum = parseFloat(
      Number(freiwilliges) +
        Number(this.selectedRewardsPrice()) +
        Number(donation)
    ).toFixed(2);
    const donationTypeOptions = [
      {
        label: 'Einmalig',
        key: 0,
      },
      {
        label: 'Täglich',
        key: 1,
      },
      {
        label: 'Wöchentlich',
        key: 2,
      },
      {
        label: 'Monatlich',
        key: 3,
      },
    ];
    const ramzanTypeOptions = [
      {
        label: 'Täglich',
        key: 0,
      },
      {
        label: 'Einmalig',
        key: 1,
      },
      {
        label: 'Wöchentlich',
        key: 2,
      },
      {
        label: 'Monatlich',
        key: 3,
      },
    ];
    const totalRewardAmount = JSON.parse(
      localStorage.getItem('answers')
    )?.reduce((prev, curre) => {
      return prev + curre.price;
    }, 0);
    const total =
      Number(localStorage.getItem('amount') || 0) +
      Number(localStorage.getItem('tips') || 0) +
      (totalRewardAmount || 0);
    const {
      selectedOption,
      donationLessThenFive,
      canChangeFeriwilliges,
      lastDonation,
    } = this.state;

    const usedDonation = canChangeFeriwilliges
      ? Number(donation) + Number(this.selectedRewardsPrice())
      : Number(lastDonation) + Number(this.selectedRewardsPrice());

    // TODO CHANGE HOW THIS WORKS
    const changeFreiwillings = (value) => {
      this.props.saveDonation(value);
      const totalDonation = value
        ? Number(value.replaceAll('.', '').replaceAll(',', '.')) +
          Number(this.selectedRewardsPrice())
        : 0 + Number(this.selectedRewardsPrice());

      // Mapping the Value to the desired values
      const feriwilliges = this.mapDonatiosToValue(totalDonation);

      // Check the value and Change it
      if (totalDonation < 5) {
        this.setState({ donationLessThenFive: true });

        //if (canChangeFeriwilliges) {
        //  setFreiwilligesState(this.numberToString(1));
        //}
      } else {
        this.setState({ donationLessThenFive: false });

        setDonationState(
          this.numberToString(
            value ? Number(value.replaceAll('.', '').replaceAll(',', '.')) : 0
          )
        );
        //if (canChangeFeriwilliges) {
        //  setFreiwilligesState(this.numberToString(feriwilliges));
        //}
      }
    };
    // Get Payment_intend Check for paypal after redirect
    const searchParams = new URLSearchParams(document.location.search);
    const payment_id = searchParams.get('paymentId');
    const payment_intent = searchParams.get('payment_intent');
    return (
      <>
        <DonationWrapper donationLessThenFive={donationLessThenFive}>
          <Form
            validateMessages={formValidateMessages}
            ref={this.props.formRef}
            initialValues={{
              donation,
              freiwilliges,
            }}
            scrollToFirstError
          >
            <div>
              <H1 className="payment_summary">{this.props.project_name === 'ramadan2025' ? 'Deine Ramadan-Unterstützung 2025' : 'Zahlungsübersicht'}</H1>

              {!isTicketable && (
                <>
                  <FormItem
                    name={'donation'}
                    label={
                      this.props.selectedRewards
                        ? 'Deine Unterstützung'
                        : 'Unterstützung'
                    }
                    className="new-donation-input donation-input-failed"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      () => ({
                        validator() {
                          if (!donationLessThenFive) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('Der Mindestgesamtbetrag beträgt 5 €')
                          );
                        },
                      }),
                    ]}
                  >
                    <div className="new-currency-container">
                      <CurrencyInput
                        allowNegativeValue={false}
                        decimalSeparator=","
                        groupSeparator="."
                        placeholder="0,00"
                        defaultValue={
                          payment_id == undefined || payment_intent == undefined
                            ? donation
                            : Number(localStorage.getItem('amount')).toFixed(2)
                        }
                        autoFocus
                        onValueChange={(value) => changeFreiwillings(value)}
                        disabled={isTicketable}
                        className="new-currency-input"
                      />
                      <span className="new-currency-symbol">€</span>
                    </div>
                  </FormItem>
                  
                  {this.props.recurringCheck && <div className={`${this.props.project_name === 'ramadan2025' && "hide-Rhythmus "} label-blk`}>
                    <FormItem
                      label={
                        <div className="text-setting">
                          <div className="text">Rhythmus</div>
                          <Tooltip
                            title={
                              <span style={{ color: '#fff' }}>
                                Wiederkehrende Unterstützungen sind nur per
                                Kreditkarte möglich. Sie können jederzeit über
                                den Link in der Bestätigungsemail deaktiviert
                                werden. Ein commonsplace-Account ist
                                erforderlich, und nicht registrierte Nutzer
                                müssen sich während des Prozesses anmelden. Zum
                                Projektende endet die Unterstützung.
                              </span>
                            }
                            color="#95bd51"
                            overlayClassName="custom-tooltip"
                          >
                            <Button type="link" style={{ padding: '0px 5px' }}>
                              <InfoCircleOutlined
                                style={{ color: 'black', fontSize: '20px', paddingLeft: '4px', paddingTop: '4px' }}
                              />
                            </Button>
                          </Tooltip>
                        </div>
                      }
                      className="new-donation-input donation-input-failed donation-input-failed"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <DropDown
                        setSelectedKey={this.handleSetSelectedKey}
                        options={this.props.project_name ? ramzanTypeOptions : donationTypeOptions}
                        className="new-rhythm-container"
                      />
                    </FormItem>
                  </div>}
                 
                </>
              )}
              {/* {donationLessThenFive && (
              <DonationLessThenFiveError>
                Der Mindestbeitrag liegt bei 5 €
              </DonationLessThenFiveError>
            )} */}

              <ChooseRewards
                isTicketable={isTicketable}
                projectId={projectId}
                originalRewardId={originalRewardId}
                donationLessThenFive={donationLessThenFive}
                setAllRewards={this.props.setAllRewards}
                setSelectedRewards={this.props.setSelectedRewards}
                setAddressRequired={this.props.setAddressRequired}
                allRewards={this.props.allRewards}
                selectedRewards={this.props.selectedRewards}
                handleCLientSecret={this.props.handleCLientSecret}
                formRef={this.props.formRef}
              />

              <div className="freiwilliges-input-wrapper">
                <div
                  className={
                    showOption
                      ? 'freiwilliges-input show-options'
                      : 'freiwilliges-input'
                  }
                >
                  <TipSelector
                    usedDonation={usedDonation}
                    setFreiwilligesState={setFreiwilligesState}
                    defaultTip={this.mapDonatiosToValue(usedDonation)}
                  />
                </div>
              </div>

              {/* <Danke handelShowModal={handelShowModal} /> */}
              <div className="gesamtsumme-container">
                <h2 className="gesamtsumme-title">Zusammenfassung</h2>

                <div className="gesamtsumme">
                  <div className="summary-item">
                    <span className="summary-value">Deine Unterstützung</span>
                    <span className="summary-value">
                      {numberWithCommas(usedDonation.toFixed(2) || '0,00')} €
                    </span>
                  </div>

                  <div className="summary-item">
                    <span className="summary-value">
                      Unterstützung für commonsplace
                    </span>
                    <span className="summary-value no-line-breaks">
                      {numberWithCommas(Number(freiwilliges).toFixed(2) || '0,00')} €
                    </span>
                  </div>

                  <hr className="summary-divider" />

                  <div className="summary-item summary-total">
                    <span className="summary-label">Gesamt</span>
                    <span className="summary-value">
                      {payment_id != undefined || payment_intent != undefined
                        ? numberWithCommas(total.toFixed(2) || '0,00')
                        : numberWithCommas(totalPriceSum || '0,00')}
                      €
                    </span>
                  </div>
              </div>
              </div>

              {/* {!showPaymentForm && (
              <PrimaryButton
                className="gesamtsumme-button"
                type="button"
                minWidth={'100%'}
                onClick={() => {
                  setShowPaymentFormState(true);
                  setCurrentViewState('info');
                }}
              >
                Weiter zur Zahlung
              </PrimaryButton>
            )} */}
            </div>
          </Form>
        </DonationWrapper>
        <Modal
          showModal={showGoodiesLimitModal}
          handelCloseModal={this.handelCloseGoodiesLimitModal}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h1 style={{ color: '#95bd51' }}>Goodies</h1>
            {goodiesWithLimitReached?.map((goodie) => {
              return <p>Goodie: {goodie?.title}</p>;
            })}
            {isTicketable ? (
              <p>Ticket Limit erreicht</p>
            ) : (
              <p> Maximales Goodies-Limit erreicht! </p>
            )}
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    donationType: state.donationType,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveKey: (key) => dispatch({ type: 'SAVE_KEY', payload: key }),
    saveDonation: (amount) =>
      dispatch({ type: 'SAVE_AMOUNT', payload: amount }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Donation);
