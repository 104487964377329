import styled from '@emotion/styled';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Spin } from 'antd';
import { PrimaryButton, Colors, H1, device, H3 } from 'utils';
import { Link, Redirect } from 'react-router-dom';
import { SingUpForm, ForgetPassword } from 'components';
import { authActions } from 'redux/actions';
import PropTypes from 'prop-types';
import { GoogleLogin } from '@react-oauth/google';
import LoginWithGoogle from './LoginWithGoogle';

const FormItem = Form.Item;
const LoginFormWrapper = styled.div`
  min-width: 500px;
  padding: 0 50px 40px;
  @media ${device.allMobile} {
    min-width: 1px;
    padding: 0;
    max-width: 100%;
  }
  h1 {
    font-size: 25px;
    font-weight: bold;
  }
  .ant-form-item:last-of-type,
  .ant-form-item:last-of-type button {
    margin: 0;
    min-width: 100%;
  }
  .ant-form-item {
    display: block;
    .ant-form-item-label {
      color: ${Colors.GreenColor};
      margin-bottom: 10px;
      label {
        font-weight: bold;
        font-size: 18px;
      }
    }
    .ant-form-item-control-input-content > input,
    .ant-input-affix-wrapper {
      width: 100%;
      padding: 10px;
      border: 1px solid ${Colors.GreenColor};
      border-radius: 10px;
    }
  }
  .login-actions {
    display: flex;
    justify-content: space-between;
    @media ${device.allMobile} {
      font-size: 12px;
    }
  }
`;

class LoginForm extends Component {
  static propTypes = {
    user: PropTypes.object,
    loading: PropTypes.bool,
    errors: PropTypes.array,
    login: PropTypes.func.isRequired,
  };

  state = {
    currentForm: 'login',
    redirectToProfile: false, // Add a state variable to manage redirection
  };

  onFinish = (values) => {
    const { login } = this.props;
    login(values);
    this.setState({ redirectToProfile: true });
  };

  handleCurrentFormState = (currentForm) => {
    this.setState({ currentForm });
  };

  handleSuccess = (response) => {
    const token = response.credential;
    this.props.loginWithGoogle({ token });
    this.setState({ redirectToProfile: true });
  };

  handleError = () => {
    console.log('Google login failed');
  };

  render() {
    const { currentForm, redirectToProfile } = this.state;
    const {
      loading,
      user,
      navigate,
      handelCloseModal,
      setDoPayment,
      donationType,
      handleGetRecurringPayment,
    } = this.props;

    const userToken = localStorage.getItem('access-token');
    const client = localStorage.getItem('client');
    const isLoggedIn = !user?.user && userToken && client;

    if (isLoggedIn && navigate === false) {
      handelCloseModal();
      if (setDoPayment) {
        setDoPayment(true);
      }
    }
    if (isLoggedIn && handleGetRecurringPayment) {
      handleGetRecurringPayment();
    }

    if (redirectToProfile && isLoggedIn) {
      if(navigate === false) {
        handelCloseModal();
        if (setDoPayment) {
          setDoPayment(true);
        }
      } else {
        return <Redirect to="/profile" />;
      }
    }

    return (
      <LoginFormWrapper>
        {/* {isLoggedIn === null ||
          (navigate === undefined && (
            <Redirect to={{ pathname: '/profile' }} />
          ))} */}

        {currentForm === 'login' && (
          <Spin spinning={loading}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                textAlign: 'left',
              }}
            >
              {donationType && donationType !== 0 && navigate === false ? (
                <h3>
                  Für wiederkehrende Unterstützungen brauchst du einen Account.
                  Bitte melde dich an oder registriere dich.
                </h3>
              ) : (
                <H3>Anmelden</H3>
              )}
            </div>
            <Form name="login" onFinish={this.onFinish}>
              <FormItem
                name="email"
                label="E-Mail-Adresse"
                rules={[
                  {
                    required: true,
                    message: 'Bitte gib deine E-Mail Adresse ein.',
                  },
                ]}
              >
                <Input type="email" />
              </FormItem>

              <FormItem
                label="Passwort"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Bitte gib dein Passwort ein.',
                  },
                ]}
              >
                <Input.Password />
              </FormItem>
              <FormItem>
                <GoogleLogin
                  onSuccess={this.handleSuccess}
                  onError={this.handleError}
                />
                {/* <LoginWithGoogle
                  onSuccess={this.handleSuccess}
                  onError={this.handleError}
                /> */}
              </FormItem>
              <FormItem>
                <PrimaryButton htmltype="submit">Anmelden</PrimaryButton>
              </FormItem>
            </Form>
            <div className="login-actions">
              <Link
                className="register-button"
                to="#!"
                onClick={() => {
                  this.handleCurrentFormState('register');
                }}
              >
                Neu hier? Jetzt registrieren!
              </Link>
              <Link
                className="register-button"
                to="#!"
                onClick={() =>
                  this.setState({ currentForm: 'forget-password' })
                }
              >
                Passwort vergessen?
              </Link>
            </div>
          </Spin>
        )}
        {currentForm === 'register' && (
          <>
            <SingUpForm
              handleCurrentFormState={this.handleCurrentFormState}
              handelCloseModal={handelCloseModal}
              navigate={navigate}
            />
          </>
        )}
        {currentForm === 'forget-password' && (
          <>
            <ForgetPassword
              handleCurrentFormState={this.handleCurrentFormState}
            />
          </>
        )}
      </LoginFormWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, loading, errors, isLoggedIn } = state.authentication;
  const { donationType } = state.payment;
  return {
    user,
    loading,
    errors,
    donationType,
    isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  login: (user) => dispatch(authActions.login(user)),
  loginWithGoogle: (user) => dispatch(authActions.loginWithGoogle(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
