import React, { useEffect, useState } from 'react';
import paywithpaypal from '../../../images/paypal.png';
import { useHistory } from 'react-router';
import '../../../utils/Payment.css';
import { Form, Input, Checkbox, Spin, Button } from 'antd';
import { LoginForm } from 'components';
import { PrimaryButton, device, Modal, H2 } from 'utils';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from 'config';
import StripeForm from './StripeForm';
import RecurringPaymentForm from './RecurringPaymentForm';
import isEmpty from 'loadsh/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import StripeApplePay from './ApplePay';
import { paymentConstants } from 'redux/actions/types';
import { useLocation, useParams } from 'react-router-dom';
import ExternalLink from 'components/ExternalLink/ExternalLink';

const InfoFormWrapper = styled.div`
  @media ${device.allMobile} {
    margin-top: 0;
  }
  .ant-select-selector {
    border-radius: 10px !important;
    padding: 6.5px 11px !important;
    height: auto !important;
  }
  button {
    margin-top: 15px;
    @media ${device.allMobile} {
      margin-top: 10px;
    }
  }
  .info-input {
    label {
      display: none;
    }
  }
  .payment-options {
    display: flex;
    span {
      cursor: pointer;
      & + span {
        margin-left: 25px;
      }
    }
  }
  .remove-header {
    @media ${device.allMobile} {
      margin: 0px;
      font-size: 4px;
      padding: 0px;
    }
  }
  .remove-label {
    .ant-form-item-label {
      display: none;
    }
  }
  .ant-form-item {
    @media ${device.allMobile} {
      margin-bottom: 5px;
    }
  }
  .ant-row, .ant-form-item-row {
    width: 100% !important; /* Ensures it doesn't overflow */
  }
`;
const notification = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
  animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
  dismiss: {
    duration: 5000,
    onScreen: true,
  },
};

const formValidateMessages = {
  required: '${label} ist erforderlich. ',
  types: {
    email: 'Keine gültige E-Mail!',
    number: '${label} is not a valid number!',
  },
};

const stripePromise = loadStripe(config.REACT_APP_STRIPE_KEY);
const PaymentMethod2 = ({
  project_id,
  amount,
  tips,
  selectedRewards,
  project,
  ...props
}) => {
  const { search } = useLocation();
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);
  const [message, setMessage] = useState(null);
  const [paymentType, setPaymentType] = useState('card');
  const [showPayButton, setShowPayButton] = useState(true);
  const [clientSecret, setClientSecret] = useState(null);
  const [, setTotalAmount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [, setStripeBtn] = useState(true);
  const [userData, setUserData] = useState({});
  const [goodiesRequiresAddress, setGoodiesRequiresAddress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [, setTokenId] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [customer, setCustomer] = useState('');
  const [doPayment, setDoPayment] = useState(false);
  const [recurringPayment, setRecurringpayment] = useState(false);
  const [showLotteryModal, setShowLotteryModal] = useState(false);
  const donationType = useSelector((state) => state.payment.donationType);
  const [selectedKey, setSelectedKey] = useState(0);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [showStripeForm, setShowStripeForm] = useState(true);
  const [showLinkBtn, setShowLinkBtn] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const dispatch = useDispatch();
  const FormItem = Form.Item;
  const user = JSON.parse(localStorage.getItem('user'));
  const client = localStorage.getItem('client');
  const uid = localStorage.getItem('uid');
  const access = localStorage.getItem('access-token');
  const isLoggedIn =
    user && !isEmpty(user) && access && uid && client ? true : false;
  const rewards = selectedRewards?.map((reward) => {
    return reward?.id;
  });
  const history = useHistory();
  const getUrl = window.location.href;
  const searchParams = new URLSearchParams(document.location.search);
  const account_id = searchParams.get('PayerID');
  const payment_id = searchParams.get('paymentId');
  const payment_intent = searchParams.get('payment_intent');
  const data = JSON.parse(localStorage.getItem('after_redirect'));
  const [formValues, setFormValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    anonymous: false,
  });
  const options = {
    appearance: { theme: 'stripe' },
    clientSecret,
  };
  const extractRewards = () => {
    let rewards_test = {};
    selectedRewards?.forEach((reward) => {
      if (!rewards_test.hasOwnProperty(rewards_test.id))
        rewards_test[reward.id] = [];
      rewards_test[rewards_test.id]?.push(rewards_test.answers);
    });
  };
  useEffect(() => {
    fetch('payments/intent_create', {
      method: 'POST',
      body: JSON.stringify({
        amount: amount !== 0 ? amount : Number(localStorage.getItem('amount')),
        project_id,
      }),
    })
      .then((res) => {
        localStorage.setItem('payment_intent_id', JSON.stringify(res.payment_intent.id))
        setPaymentIntentId(res.payment_intent.id);
        setClientSecret(res.payment_intent.client_secret);
      })
      .catch((err) => {
        setMessage(err);
        console.log(err);
      });
  }, []);

  const updateIntentHandle = (isAnonymous) => {
    const url = window.location.href;
    const parameters = new URLSearchParams(url);
    const rewardId = parameters.get('rewardId');
    const rewards = selectedRewards?.map((reward) => {
      return reward?.id;
    });
    const getAnswers =
      localStorage.getItem('answers') !== undefined &&
      JSON.parse(localStorage.getItem('answers'));
    const _answer = getAnswers?.map((item) => {
      item.answers.unshift(item.id.toString());
      return item.answers;
    });
    fetch(`payments/intent_update?id=${paymentIntentId}`, {
      method: 'PUT',
      body: JSON.stringify({
        payment_intent: {
          first_name:
            user?.first_name == null ? formValues.first_name : user?.first_name,
          last_name:
            user?.last_name == null ? formValues.last_name : user?.last_name,
          email: user?.email == null ? formValues.email : user?.email,
          user: true,
          project_id: project_id,
          amount: amount,
          lottery: false,
          anonymous: isAnonymous ?? false,
          donation_type: project.title === 'Ramdan2025' ? 1 : selectedKey,
          tips: tips,
          ...(selectedRewards.length != 0 && {}),
          recurring: selectedKey === 0 ? false : true,
          answers: _answer || [],
        },
        reward_ids:
          rewardId === null
            ? rewards && rewards?.length === 0
              ? []
              : rewards
            : [rewardId],
      }),
      redirect: 'follow',
    });
  };
  const onFinish = (values) => {
    extractRewards();
    setUserData(values);
    localStorage.setItem('user_data', JSON.stringify(values));
    localStorage.setItem('title', project.title ?? '');
    if (paymentType !== 'card' || paymentType !== 'paypal') {
      if (!isLoggedIn && !showModal && selectedKey !== 0) {
        setShowModal(true);
      }
    }
    if (
      (paymentType == 'card' &&
        values &&
        selectedKey === 0 &&
        !(recurringPayment && doPayment)) ||
      (paymentType == 'card' &&
        values &&
        recurringPayment &&
        doPayment &&
        selectedKey !== 0) ||
      (paymentType == 'card' &&
        values &&
        !recurringPayment &&
        doPayment &&
        selectedKey !== 0)
    ) {
      localStorage.setItem('tips', JSON.stringify(tips));
      localStorage.setItem('amount', JSON.stringify(amount));
      setIsProcessing(true);
      const user = JSON.parse(localStorage.getItem('user'));
      const url = window.location.href;
      const parameters = new URLSearchParams(url);
      const rewardId = parameters.get('rewardId');
      const rewards = selectedRewards?.map((reward) => {
        return reward?.id;
      });
      const getAnswers =
        localStorage.getItem('answers') !== undefined &&
        JSON.parse(localStorage.getItem('answers'));
      const _answer = getAnswers?.map((item) => {
        item.answers.unshift(item.id.toString());
        return item.answers;
      });
      // Create Payment-Intent
      fetch(`payments/intent_update?id=${paymentIntentId}`, {
        method: 'PUT',
        body: JSON.stringify({
          payment_intent: {
            first_name:
              user?.first_name == null ? values.first_name : user?.first_name,
            last_name:
              user?.last_name == null ? values.last_name : user?.last_name,
            email: user?.email == null ? values.email : user?.email,
            user: true,
            project_id: project_id,
            amount: amount,
            lottery: values?.lottery || false,
            anonymous: values.anonymous || false,
            tips: tips,
            ...(selectedRewards.length != 0 && {
              street: values?.street,
              building_number: values.building_number,
              zip_code: values.zip_code,
              city: values?.city,
            }),
            recurring: selectedKey === 0 ? false : true,
            answers: _answer || [],
          },
          reward_ids:
            rewardId === null
              ? rewards && rewards?.length === 0
                ? []
                : rewards
              : [rewardId],
        }),
        redirect: 'follow',
      })
        .then(async (result) => {
          setIsProcessing(false);
          setClientSecret(result?.payment_intent?.client_secret);
          setTotalAmount(result?.payment_intent?.amount);
          localStorage.setItem('rewards', JSON.stringify(result?.reward_ids));
          localStorage.setItem('payment_intent_customer', JSON.stringify(result?.payment_intent?.customer));
          setStripeBtn(true);
          setTokenId(result?.payment_intent?.id);
          setCustomer(result?.payment_intent?.customer);
          selectedKey > 0
            ? await onSubmitPayPalPayment(
              values,
              result?.payment_intent?.customer
            )
            : await onSubmitPayment(values);
        })
        .catch((err) => {
          setIsProcessing(false);
          setMessage('Something wrong');
        });
    }
    if (paymentType == 'paypal') {
      localStorage.setItem(
        'after_redirect',
        JSON.stringify({
          ...values,
          projectTitle: new URLSearchParams(search).get('name') ?? '',
        })
      );
      localStorage.setItem('tips', JSON.stringify(tips));
      localStorage.setItem('amount', JSON.stringify(amount));
      setIsProcessing(true);
      fetch('create_payment', {
        method: 'POST',
        body: JSON.stringify({
          email: user?.email == null ? values.email : user?.email,
          project_id: project_id,
          amount,
          tips: tips,
          reward_ids: rewards,
          return_url: getUrl,
          lottery: values?.lottery || false,
          anonymous: values?.anonymous == undefined ? false : values?.anonymous,
          message: values?.message == undefined ? '' : values?.message,
          ...(user !== null && { user_id: user?.id }),
          ...(user === null && {
            first_name: values.first_name,
            last_name: values.last_name,
          }),
          ...(selectedRewards.length != 0 && {
            street: values?.street,
            building_number: values.building_number,
            zip_code: values.zip_code,
            city: values?.city,
          }),
        }),
      })
        .then(async (result) => {
          localStorage.setItem('rewards', JSON.stringify(result?.reward_ids));
          const geturl = result?.response?.links?.find((item) => {
            return item?.rel == 'approval_url';
          });
          if (geturl) {
            window.open(`${geturl?.href}`, '_self');
            setIsProcessing(false);
          } else {
            setMessage('Something wrong');
            setIsProcessing(false);
          }
          selectedKey > 0
            ? await onSubmitPayPalPayment(values, '')
            : await onSubmitPayment(values);
        })
        .catch((err) => setIsProcessing(false));
    }
  };
  const { id } = useParams();
  const param_id = id;

  const onSubmitPayment = async (values) => {
    const _answer = selectedRewards.map((item) => {
      item.answers.unshift(item.id.toString());
      return item.answers;
    });
    setIsProcessing(true);
    localStorage.setItem(
      'after_redirect',
      JSON.stringify({
        ...values,
        projectTitle: new URLSearchParams(search).get('name') ?? '',
      })
    );
    // Strip APi Call for Payment Deduction
    if (!stripe || !elements) {
      return;
    }
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/project/${project_id}/donation?donation=${amount}`,
      },
      redirect: 'if_required',
    });
    const { error, paymentIntent } = result;
    if (error) {
      setMessage(error.message);
      setIsProcessing(false);
    } else if (paymentIntent) {
      setIsProcessing(true);
      fetch('payments/direct_payment', {
        method: 'POST',
        body: JSON.stringify({
          project_id: project_id,
          first_name: values?.first_name,
          email: values?.email,
          last_name: values?.last_name,
          amount:
            amount !== 0 ? amount : Number(localStorage.getItem('amount')),
          tips: tips,
          reward_ids: rewards,
          answers: _answer,
          payment_intent: paymentIntent?.id,
          donation_type: selectedKey,
          anonymous: values?.anonymous,
          message: values?.message,
          lottery: values?.lottery || false,
          ...(selectedRewards.length !== 0 && {
            street: values?.street,
            building_number: values.building_number,
            zip_code: values.zip_code,
            city: values?.city,
          }),
        }),
      })
        .then((result) => {
          if (result?.success === true) {
            setIsProcessing(false);
            redirect();
          } else {
            setMessage('Something wrong,please try again');
          }
        })
        .catch((err) => {
          setIsProcessing(false);
          setMessage('Something wrong,please try again');
        });
    } else {
      setMessage('Unexpected Error');
    }
  };

  const onSubmitPayPalPayment = async (values, customer) => {
    setIsProcessing(true);
    localStorage.setItem(
      'after_redirect',
      JSON.stringify({
        ...values,
        projectTitle: new URLSearchParams(search).get('name') ?? '',
      })
    );
    // Strip APi Call for Payment Deduction
    if (!stripe || !elements) {
      return;
    }

    localStorage.setItem('tips', JSON.stringify(tips));
    localStorage.setItem('amount', JSON.stringify(amount));
    setIsProcessing(true);
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: project.title === 'Ramdan2025' ? `${window.location.origin}/ramadan2025` : `${window.location.origin}/project/${project_id}/donation?donation=${amount}`,
      },
      redirect: 'if_required',
    });
    const { error, paymentIntent } = result;
    if (error) {
      setMessage(error.message);
      setIsProcessing(false);
      setRecurringpayment(false);
    } else if (paymentIntent) {
      setPaymentId(paymentIntent?.payment_method);
      customer && (await recurringDirectPayment(customer));
    } else {
      setMessage('Unexpected Error');
    }
  };
  const handleCheckbox = () => {
    setStripeBtn(true);
    setMessage(null);
  };

  useEffect(() => {
    if (new URLSearchParams(search).get('name'))
      localStorage.setItem(
        'projectTitle',
        new URLSearchParams(search).get('name') ?? ''
      );
  }, []);
  const handelCloseModal = () => {
    setShowModal(false);
  };

  const redirect = () => {
    const title =
      project.title ||
      new URLSearchParams(search).get('name') ||
      JSON.parse(localStorage.getItem('after_redirect') ?? '{}')
        ?.projectTitle ||
      localStorage.getItem('projectTitle');
    if (project.is_ticketable) {
      history.push(
        `/events-success?${title ? `title=${title}` : ''
        }&amount=${amount}&tips=${tips || localStorage.getItem('tips')
        }&id=${project_id}&g=${selectedRewards.map((item) => item.price)}`
      );
    } else {
      history.push(
        `/thank-you-page?${title ? `title=${title}` : ''
        }&amount=${amount > 5 ? amount : JSON.parse(localStorage.getItem('amount')) ?? amount}&tips=${tips || localStorage.getItem('tips')
        }&id=${project_id}&g=${selectedRewards.map((item) => item.price)}`
      );
    }
  };
  useEffect(() => {
    setSelectedKey(project.title === 'Ramdan2025' ? 1 : donationType);
    const isCardOnly = project.title === 'Ramdan2025' ? 1 > 0 : donationType > 0;
    const isMethodChange =
      (donationType > 0 && selectedKey === 0) ||
      (donationType === 0 && selectedKey > 0) || (project.title === 'Ramdan2025')

    if (paymentIntentId && isMethodChange) {
      setShowStripeForm(false);

      fetch(`payments/intent_update?id=${paymentIntentId}`, {
        method: 'PATCH',
        body: JSON.stringify({ payment_intent: { recurring: isCardOnly } }),
      })
        .then((res) => {
          setShowStripeForm(true);
        })
        .catch((err) => {
          console.log('err');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donationType, paymentIntentId]);

  useEffect(() => {
    if (project?.is_patreon) {
      setSelectedKey(0);
      dispatch({ type: 'SAVE_KEY', payload: 0 });
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: paymentConstants.SAVE_EMAIL,
      payload: project?.initiator_email || '',
    });
  }, [project]);

  // if goodies , amount and tips will be changed then intent will create again
  useEffect(() => {
    if (paymentType == 'card') {
      setStripeBtn(true);
      setMessage(null);
    }
    setShowPayButton(false);
    setTimeout(() => setShowPayButton(true), 500);
    updateIntentHandle(formValues.anonymous);
  }, [selectedRewards, amount, tips]);

  // For Paypal payment after redirect
  useEffect(async () => {
    if (payment_id != undefined) {
      redirect();
      const getAnswers =
        localStorage.getItem('answers') !== undefined &&
        JSON.parse(localStorage.getItem('answers'));
      const _answer = getAnswers?.map((item) => {
        item.answers.unshift(item.id.toString());
        return item.answers;
      });
      setIsProcessing(true);
      await fetch('transfer_amount', {
        method: 'POST',
        body: JSON.stringify({
          account_id: account_id,
          payment_id: payment_id,
          email: data?.email,
          project_id: project_id,
          lottery: data?.lottery || false,
          answers: _answer,
          amount: localStorage.getItem('amount'),
          tips: localStorage.getItem('tips'),
          reward_ids:
            localStorage.getItem('rewards') != undefined &&
            JSON.parse(localStorage.getItem('rewards')),
          anonymous: data?.anonymous == undefined ? false : data?.anonymous,
          message: data?.message == undefined ? '' : data?.message,
          ...(user !== null && {
            user_id: user?.id,
            email: user?.email,
            first_name: user?.first_name,
            last_name: user?.last_name,
          }),
          ...(user === null && {
            first_name: data?.first_name,
            last_name: data?.last_name,
            street: data?.street,
            building_number: data.building_number,
            zip_code: data?.zip_code,
            city: data?.city,
          }),
        }),
      })
        .then((result) => {
          setIsProcessing(false);
          if (result?.payment_response?.state == 'approved') {
            dispatch({ type: paymentConstants.SAVE_KEY, payload: 0 });
          } else if (result?.message == 'payment already exist') {
            history.push(`/project/${result?.url}`);
          }
        })
        .catch((err) => {
          setIsProcessing(false);
          setMessage('Something Wrong,Please try again later');
          store.addNotification({
            ...notification,
            title: 'Fehler!',
            type: 'danger',
            message: 'Something Wrong,Please try again later',
          });
        });
    }
  }, []);

  // Payment other then Card items
  useEffect(() => {
    if (payment_intent != undefined) {
      const getAnswers =
        localStorage.getItem('answers') !== undefined &&
        JSON.parse(localStorage.getItem('answers'));
      const _answer = getAnswers?.map((item) => {
        item.answers.unshift(item.id.toString());
        return item.answers;
      });
      const user_data = JSON.parse(localStorage.getItem('user_data'));
      setIsProcessing(true);
      if (project.title === 'Ramdan2025') {
        const customerLocal = JSON.parse(localStorage.getItem('payment_intent_customer'))
        customerLocal && recurringDirectPayment(customerLocal)
      } else {
        fetch('payments/direct_payment', {
          method: 'POST',
          body: JSON.stringify({
            first_name: userData?.first_name
              ? userData?.first_name
              : user_data?.first_name,
            email: userData?.email ? userData?.email : user_data?.email,
            last_name: userData.last_name
              ? userData.last_name
              : user_data?.last_name,
            project_id: project_id,
            amount: localStorage.getItem('amount'),
            tips: localStorage.getItem('tips'),
            reward_ids:
              localStorage.getItem('rewards') != undefined &&
              JSON.parse(localStorage.getItem('rewards')),
            answers: _answer,
            payment_intent: payment_intent,
            anonymous: userData?.anonymous
              ? userData?.anonymous
              : data?.anonymous,
            message: userData?.message ? userData?.message : data?.message,
            lottery: userData?.lottery || false,
            ...(user !== null && { user_id: user?.id }),
            ...(getAnswers?.length != 0 && {
              street: data?.street,
              building_number: data?.building_number,
              zip_code: data?.zip_code,
              city: data?.city,
            }),
          }),
        })
          .then((result) => {
            if (result?.success === true) {
              setIsProcessing(false);
              // localStorage.setItem("answers",JSON.stringify([]));
              redirect();
              dispatch({ type: paymentConstants.SAVE_KEY, payload: 0 });
            } else if (result?.success === false) {
              setIsProcessing(false);
              history.push(`/project/${result?.url}`);
              // localStorage.setItem("answers",JSON.stringify([]));
              setMessage('');
            } else {
              setMessage('Something wrong,please try again');
            }
          })
          .catch((err) => {
            setIsProcessing(false);
            setMessage('Something wrong,please try again');
          });
      }
    }
  }, []);

  useEffect(() => {
    const hasAddressRequired = selectedRewards.some(
      (reward) => reward.address_required
    );
    setGoodiesRequiresAddress(hasAddressRequired);
  }, [selectedRewards]);

  useEffect(() => {
    if (isLoggedIn && !showModal) {
      setDoPayment(true);
    }
  }, [isLoggedIn, showModal]);

  const recurringDirectPayment = async (customer) => {
    const payment_intent_id_local = JSON.parse(localStorage.getItem('payment_intent_id'))
    const url = window.location.href;
    const parameters = new URLSearchParams(url);
    const user = JSON.parse(localStorage.getItem('user'));
    const rewardId = parameters.get('rewardId');
    const rewards = selectedRewards?.map((reward) => {
      return reward?.id;
    });
    fetch('payments/recurring_direct_payment', {
      method: 'POST',
      body: JSON.stringify({
        first_name: user && user.first_name,
        last_name: user && user.last_name,
        email: user && user.email,
        user_id: user?.id,
        project_id: project_id,
        amount: Number(JSON.parse(localStorage.getItem('amount'))) > 5 ? Number(JSON.parse(localStorage.getItem('amount'))) : amount,
        tips: tips !== 0 ? tips : Number(JSON.parse(localStorage.getItem('tips'))),
        token: paymentIntentId || payment_intent_id_local,
        payment_id: paymentId,
        customer: customer,
        message: userData.message,
        anonymous: userData.anonymous,
        lottery: userData?.lottery || false,
        donation_type: project.title === 'Ramdan2025' ? 1 : selectedKey,
        ...(selectedRewards.length != 0 && {
          street: userData?.street,
          building_number: userData.building_number,
          zip_code: userData.zip_code,
          city: userData?.city,
        }),
        reward_ids:
          rewardId === null
            ? rewards && rewards?.length === 0
              ? []
              : rewards
            : [rewardId],
      }),
      redirect: 'follow',
    })
      .then(() => {
        setIsProcessing(false);
        redirect();
        dispatch({ type: paymentConstants.SAVE_KEY, payload: 0 });
        this.props.changeSelectedType(0);
      })
      .catch((err) => {
        setIsProcessing(false);
        setMessage('Something wrong');
      });
  };

  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validatedEmail = emailRegex.test(formValues?.email);
    if (formValues?.first_name && formValues?.last_name && validatedEmail && termsCheck) {
      setShowLinkBtn(true);
    } else {
      setShowLinkBtn(false);
    }
  }, [formValues, formValues?.first_name, formValues?.last_name, formValues?.email, termsCheck]);
  return (
    <>
      <Spin spinning={isProcessing}>
        <InfoFormWrapper>
          <Form
            name="donation_info"
            onFinish={onFinish}
            // ref={(b) => (this.form = b)}
            validateMessages={formValidateMessages}
            scrollToFirstError
            style={{ marginTop: '1rem' }}
          >
            <div>
              <>
                <div className="info-input">
                  <FormItem
                    name="first_name"
                    label="Vorname"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        first_name: e.target.value,
                      })
                    }
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Vorname"
                      style={{ borderRadius: '10px' }}
                      onChange={handleCheckbox}
                    />
                  </FormItem>
                  <FormItem
                    name="last_name"
                    label="Nachname"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        last_name: e.target.value,
                      })
                    }
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Nachname"
                      style={{ borderRadius: '10px' }}
                      onChange={handleCheckbox}
                    />
                  </FormItem>
                  <FormItem
                    name="email"
                    label="Email"
                    onChange={(e) =>
                      setFormValues({ ...formValues, email: e.target.value })
                    }
                    rules={[
                      {
                        required: true,
                        type: 'email',
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      style={{ borderRadius: '10px' }}
                      type="email"
                      placeholder="Email"
                      onChange={handleCheckbox}
                    />
                  </FormItem>
                  {goodiesRequiresAddress && selectedRewards.length != 0 && (
                    <>
                      <FormItem
                        name="city"
                        label="Stadt"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Stadt"
                          style={{ borderRadius: '10px' }}
                          onChange={handleCheckbox}
                        />
                      </FormItem>

                      <FormItem
                        name="street"
                        label="Straße"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Straße"
                          style={{ borderRadius: '10px' }}
                          onChange={handleCheckbox}
                        />
                      </FormItem>

                      <FormItem
                        name="zip_code"
                        label="Postleitzahl"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          type="number"
                          style={{ borderRadius: '10px' }}
                          placeholder="Postleitzahl"
                          onChange={handleCheckbox}
                        />
                      </FormItem>

                      <FormItem
                        name="building_number"
                        label="Hausnummer"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          size="large"
                          style={{ borderRadius: '10px' }}
                          placeholder="Hausnummer"
                          onChange={handleCheckbox}
                        />
                      </FormItem>
                    </>
                  )}
                </div>
              </>
            </div>
            {/* Concent forms */}
            <div style={{ marginTop: '1.5rem' }}>
              <FormItem
                name="terms"
                onChange={(c) => {
                  updateIntentHandle(formValues.anonymous);
                  setTermsCheck(c.target.checked);
                }}
                className="remove-label"
                valuePropName="checked"
                label="Zustimmung"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error('Zustimmung erforderlich')),
                  },
                ]}
              >
                <Checkbox>
                  Ich akzeptiere
                  <Link to="/anb" target="_blank">
                    die Nutzungsbedingungen
                  </Link>{' '}
                  für commonsplace und{' '}
                  <Link to="/datenschutz" target="_blank">
                    Datenschutzbestimmungen
                  </Link>{' '}
                  und bestätige, dass ich mindestens 16 Jahre alt bin.
                </Checkbox>
              </FormItem>
              <FormItem
                name={'anonymous'}
                valuePropName="checked"
                className="check-box"
              >
                {!getUrl?.includes('ramadan2025') && <Checkbox
                  onChange={(c) => {
                    setFormValues({
                      ...formValues,
                      anonymous: c.target.checked,
                    });
                    updateIntentHandle(c.target.checked);
                  }}
                >
                  Ich möchte für die Community anonym bleiben.
                </Checkbox>}
              </FormItem>
              {param_id === '581' && (
                <FormItem
                  name={'lottery'}
                  valuePropName="checked"
                  className="remove-label"
                >
                  <Checkbox>
                    Ich nehme an der
                    <ExternalLink
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowLotteryModal(true);
                      }}
                    >
                      Umrah-Verlosung
                    </ExternalLink>{' '}
                    teil.
                  </Checkbox>
                </FormItem>
              )}
              {!getUrl?.includes('ramadan2025') && <div className="zahlungsmethode">
                <h2>Hinterlasse eine Botschaft</h2>
                <FormItem name={'message'} className="deine-label">
                  <Input.TextArea
                    placeholder="Deine Nachricht (optional)"
                    maxLength={150}
                    showCount
                  />
                </FormItem>
              </div>}
            </div>

            <div className="payment_container">
              <h2 className="payment_label_heading">Bezahlmethode</h2>
              <div className="secure_message">
                Dies ist eine sichere und SSL-verschlüsselte Zahlung. Ihre
                Kreditkarte Details sind sicher.
              </div>
              {message && <div className="response_message">{message}</div>}
              <div>
                {paymentType === 'card' &&
                  clientSecret &&
                  stripePromise &&
                  showStripeForm &&
                  selectedKey !== 0 && (
                    // tokenId &&
                    // customer &&
                    <>
                      {!isLoggedIn && (
                        <div style={{ textAlign: 'center', background: '#5eee97', height: '40px', marginBottom: '10px', cursor: 'pointer' }} onClick={() => setShowModal(true)}>
                          <p style={{ padding: '10px', fontSize: '15px', fontWeight: 'bold' }}>Pay with Link</p>
                        </div>
                      )}
                      {(selectedKey === 1 && showPayButton && project.title === "Ramdan2025" && isLoggedIn) && (
                        <div style={{ position: "relative" }}>
                          {
                            !(showLinkBtn) && (
                              <button type='submut' style={{ zIndex: 20, position: "absolute", inset: 0, textAlign: 'center', background: 'transparent', height: '40px', marginTop: 0, cursor: 'pointer', border: "none", width: "100%" }}>
                              </button>
                            )
                          }
                          <div style={{ marginBottom: '10px' }}>
                            <Elements stripe={stripePromise}>
                              <StripeApplePay
                                redirect={redirect}
                                userData={userData}
                                clientSecret={clientSecret}
                                updateIntentHandle={updateIntentHandle}
                                totalAmount={
                                  amount +
                                  tips +
                                  selectedRewards.reduce(
                                    (prev, reward) => prev + reward.price,
                                    0
                                  )
                                }
                              />
                            </Elements>
                          </div>
                        </div>
                      )}
                      <Elements options={options} stripe={stripePromise}>
                        <RecurringPaymentForm
                          options={options}
                          selectedRewards={selectedRewards}
                          paymentType={paymentType}
                          project_id={project_id}
                          setPaymentId={setPaymentId}
                          amount={amount}
                          tips={tips}
                          setMessage={setMessage}
                          setIsProcessing={setIsProcessing}
                          isProcessing={isProcessing}
                          values={userData}
                          selectedKey={selectedKey}
                          isTicketable={project?.is_ticketable}
                          recurringPayment={recurringPayment}
                          setRecurringpayment={setRecurringpayment}
                          setElements={setElements}
                          setStripe={setStripe}
                        />
                      </Elements>
                    </>
                  )}

                {paymentType === 'card' &&
                  clientSecret &&
                  stripePromise &&
                  selectedKey === 0 &&
                  showStripeForm &&
                  !customer && (
                    <div
                      className="payment_container"
                      style={{ marginTop: '1rem', marginBottom: '20px' }}
                    >
                      {selectedKey === 0 && showPayButton && (
                        <div style={{ position: "relative" }}>
                          {
                            !(showLinkBtn) && (
                              <button type='submut' style={{ zIndex: 20, position: "absolute", inset: 0, textAlign: 'center', background: 'transparent', height: '40px', marginTop: 0, cursor: 'pointer', border: "none", width: "100%" }}>
                              </button>
                            )
                          }
                          <div style={{ marginBottom: '10px' }}>
                            <Elements stripe={stripePromise}>
                              <StripeApplePay
                                redirect={redirect}
                                userData={userData}
                                clientSecret={clientSecret}
                                totalAmount={
                                  amount +
                                  tips +
                                  selectedRewards.reduce(
                                    (prev, reward) => prev + reward.price,
                                    0
                                  )
                                }
                              />
                            </Elements>
                          </div>
                        </div>
                      )}
                      <Elements options={options} stripe={stripePromise}>
                        <StripeForm
                          options={options}
                          selectedRewards={selectedRewards}
                          paymentType={paymentType}
                          project_id={project_id}
                          amount={amount}
                          tips={tips}
                          setMessage={setMessage}
                          setIsProcessing={setIsProcessing}
                          isProcessing={isProcessing}
                          values={userData}
                          selectedKey={selectedKey}
                          isTicketable={project?.is_ticketable}
                          project={project}
                          setElements={setElements}
                          setStripe={setStripe}
                          onSubmitHandle={() => {
                            selectedRewards.length
                              ? localStorage.setItem(
                                'goodies',
                                selectedRewards.reduce(
                                  (prev, reward) => `${prev}${reward.price},`,
                                  ''
                                )
                              )
                              : localStorage.removeItem('goodies');
                            localStorage.setItem(
                              'projectTitle',
                              new URLSearchParams(search).get('name') ??
                              project?.title ??
                              ''
                            );
                          }}
                        />
                      </Elements>
                    </div>
                  )}
                {project?.paypal_check && selectedKey === 0 && (
                  <PrimaryButton
                    type="submit"
                    minWidth={'100%'}
                    className=""
                    disabled={isProcessing}
                    onClick={() => setPaymentType('paypal')}
                    style={{
                      background: 'transparent',
                      border: '1px solid transparent',
                      padding: '0',
                      textAlign: 'left',
                      marginBottom: '10px',
                    }}
                  >
                    <img
                      className="paypal_img"
                      src={paywithpaypal}
                      alt="paypal-icon"
                    />
                  </PrimaryButton>
                )}
              </div>
            </div>
          </Form>
          <Modal showModal={showModal} handelCloseModal={handelCloseModal}>
            <LoginForm
              navigate={false}
              handelCloseModal={handelCloseModal}
              setDoPayment={setDoPayment}
            />
          </Modal>
          <Modal
            showModal={showLotteryModal}
            handelCloseModal={() => setShowLotteryModal(false)}
          >
            <ol
              style={{
                display: '',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '10px',
                }}
              >
                <H2>Informationen zur Umrah-Verlosung</H2>
              </div>
              <li>Die Teilnahme an der Umrah-Verlosung ist freiwillig.</li>
              <li>
                Der Gewinner wird kurz vor der Umrah-Reise für Blinde aus allen
                Teilnehmern zufällig ausgewählt, privat benachrichtigt und auf{' '}
                <a
                  href="https://www.instagram.com/makarim_reisen"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'green' }}
                >
                  Instagram
                </a>{' '}
                bekanntgegeben.
              </li>
              <li>
                Der Gewinner erhält eine Umrah-Reise von Makarim. Weitere
                Informationen findest Du unter{' '}
                <a
                  href="https://www.soultreat.de/makarim/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'green' }}
                >
                  https://www.soultreat.de/makarim/
                </a>
                .
              </li>
              <li>
                Die Verlosung ist islamkonform und wurde entsprechend geprüft
                und genehmigt.
              </li>
              <li>
                Der Gewinner kann das Reisedatum innerhalb von zwei Jahren,
                basierend auf den verfügbaren Angeboten von Soultreat/Makarim,
                frei wählen.
              </li>
            </ol>
          </Modal>
        </InfoFormWrapper>
      </Spin>
    </>
  );
};

export default PaymentMethod2;
