import React, { useState, useEffect } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';

const StripeApplePay = ({ clientSecret, totalAmount, redirect, updateIntentHandle }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (!stripe || !elements || !clientSecret || !totalAmount) {
      return;
    }

    let amount = parseFloat(totalAmount);

    if (isNaN(amount) || amount <= 0) {
      console.error("Invalid total amount");
      return;
    }

    amount = Math.round(amount * 100);

    const paymentRequest = stripe.paymentRequest({
      country: 'DE',
      currency: 'eur',
      total: { label: 'Total', amount: amount },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    updateIntentHandle && updateIntentHandle(false)
    paymentRequest.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(paymentRequest);

        const prButton = elements.create('paymentRequestButton', {
          paymentRequest,
          style: { paymentRequestButton: { type: 'buy' } },
        });

        prButton.mount('#payment-request-button');

        paymentRequest.on('paymentmethod', async (ev) => {
          const { paymentIntent, error: confirmError } =
            await stripe.confirmCardPayment(
              clientSecret,
              { payment_method: ev.paymentMethod.id },
              { handleActions: false }
            );

          if (confirmError) {
            ev.complete('fail');
          } else {
            ev.complete('success');
            if (paymentIntent.status === 'requires_action') {
              const { error } = await stripe.confirmCardPayment(clientSecret);
              if (error) {
                console.log('Error: ', error);
              } else {
                localStorage.removeItem('goodies');
                redirect();
              }
            } else {
              localStorage.removeItem('goodies');
              redirect();
            }
          }
        });
      } else {
        if (document.getElementById('payment-request-button'))
          document.getElementById('payment-request-button').style.display =
            'none';
      }
    });

    return () => {
      if (paymentRequest) {
        if (document.getElementById('payment-request-button'))
          document.getElementById('payment-request-button').innerHTML = ''; // Clear the previous button
      }
    };
  }, [stripe, elements, totalAmount, clientSecret]);
  return (
    <div>
      <div id="payment-request-button"></div>
    </div>
  );
};

export default StripeApplePay;
