import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuthenticated } from 'utils/Authentication';
import isEmpty from 'loadsh/isEmpty';

const LoggedInRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/protected" />
      )
    }
  />
);

const isLoggedIn = () => {
  if (window.location.href.includes('testing')) {
    return !!localStorage.getItem('STAGING_USER');
  }

  const client = localStorage.getItem('client');
  const uid = localStorage.getItem('uid');
  const access = localStorage.getItem('access-token');
  const user = localStorage.getItem('user');
  const isLoggedIn =
    user && !isEmpty(user) && access && uid && client ? true : false;

  return isLoggedIn;
};

export const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default LoggedInRoute;
