import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Space } from 'antd';
import { useSelector } from 'react-redux';
import '../../../utils/Payment.css';
import { Colors } from 'utils';
import styled from '@emotion/styled';

const DropDownWrapper = styled.div`
  /* Container to hold rhythm input */
  .dropdown-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: ${(props) =>
      props.donationLessThenFive
        ? `1px solid ${Colors.red}`
        : `1px solid #DCDCDC`} !important;
    border-radius: 10px;
    padding: 8px;
    max-width: 100%;
    height: 64px;
  }

  /* Input field */
  .selected-key {
    font-size: 20px;
    text-align: left;
    font-weight: normal !important;
    padding-left: 20px;
    overflow: hidden;
  }

  /* Symbol */
  .arrow-symbol {
    color: #6e6e6e;
    padding-right: 20px;
  }
`;

const DropDown = ({ setSelectedKey, options, className }) => {
  const selectedKey = useSelector((state) => state.payment.donationType);
  // const selectedKey = 0;
  const handleMenuClick = (e) => {
    setSelectedKey(e.key * 1);
  };

  const menu = (
    <Menu onClick={handleMenuClick} selectedKeys={[selectedKey]}>
      {options.map((item) => (
        <Menu.Item
          key={item.key}
          style={
            selectedKey == item.key
              ? { backgroundColor: 'black', color: 'white', zIndex: 0 }
              : null
          }
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  // const buttonStyle = {
  //     borderRadius: '10px',
  //     border: '1px solid black',
  //     fontWeight: 'bold',
  //     padding: '10px',
  //     maxWidth: '165px',
  //     background: 'none',
  //     width: '100%',
  //     color: 'black',
  //     height: '56px',
  //     fontSize: '20px',
  //     '@media screen and (max-width: 768px)': {
  //         fontSize: '16px',
  //         height: '40px',
  //       },
  //  };

  return (
    <>
      <DropDownWrapper>
        <Dropdown overlay={menu} trigger={['click']}>
          <Button className="dropdown-button dropdown-container">
            <span className="selected-key">{options[selectedKey].label}</span>
            <div className="arrow-symbol">
              <DownOutlined />
            </div>
          </Button>
        </Dropdown>
      </DropDownWrapper>
    </>
  );
};

export default DropDown;
