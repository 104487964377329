import React, { useEffect} from 'react';

const ExternalLinkOnLogin = ({ href }) => {
  useEffect(() => {
    window.location.href = href;
  }, [href]);

  return null;
};

export default ExternalLinkOnLogin;
