import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AdminActions } from 'redux/actions';
import { Layout } from 'components';
import { Select, Table } from 'antd';
import { Container, PrimaryButton } from 'utils';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import Papa from 'papaparse';
import FormItem from 'antd/lib/form/FormItem';
import { adminServices } from 'services';
import config from 'config';

const { Option } = Select;

const TableWrapper = styled.div`
  padding: 50px 0;
`;

const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media (max-width: 320px) {
    gap: 10px;
    overflow: hidden;
  }
`;

const perPage = 50;
const durationArr = [
  {
    title: 'Previous 99 days',
    value: 'previous99',
    id: 1,
  },
  {
    title: 'Next 99 days',
    value: 'next99',
    id: 2,
  },
];
export class AllProjects extends Component {
  state = {
    isEnabled: null, // Initial state
    isRecurring: null
  };

  handleClick = () => {
    this.setState(
      (prevState) => ({
        isEnabled: !prevState.isEnabled,
        checked: true,
      }),
      () => {
        this.callApi();
      }
    );
  };
  callApi = () => {
    const { isEnabled } = this.state;
    try {
      this.setState({ loading: true, error: null });
      const res = adminServices.getEnabled(isEnabled);
    } catch (error) {}
  };

  handleClick1 = () => {
    this.setState(
      (prevState) => ({
        isRecurring: !prevState.isRecurring,
        checked: true,
      }),
      () => {
        this.callApi1();
      }
    );
  };
  callApi1 = () => {
    const {  isRecurring } = this.state;
    try {
      this.setState({ loading: true, error: null });
      const res = adminServices.getRecurring(isRecurring);
    } catch (error) {}
  };
  static propTypes = {
    projects: PropTypes.any.isRequired,
    getAllProjects: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };
  componentDidMount() {
    const { getAllProjects } = this.props;
    getAllProjects(perPage, 1, null, null);
  }
  componentDidUpdate(prevProps) {
    if (
      this.state.isEnabled !== this.props.projects.paypal_check &&
      !this.state.checked
    )
      this.setState({
        isEnabled: this.props.projects.paypal_check,
      });
  }
  constructor(props) {
    super(props);
    this.onClickDropdown = this.onClickDropdown.bind(this);
    this.state = {
      isEnabled: true, // Initial state: button is enabled
    };
  }
  state = {
    status: null,
    page: 1,
    filter: null,
  };
  handleDownloadCSV = (data) => {
    const { projects } = this.props;

    const csvData = Papa.unparse(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'wegen_project_stats.csv';
    link.click();
  };

  async getAllProjectCsv() {
    const { filter, page, status } = this.state;
    try {
      this.setState({ loading: true, error: null });
      const res = await adminServices.getAllAdminProjectsCsv(filter, status);
      const data = res?.projects?.projects?.map((item) => {
        const {
          funding_start_date,
          funding_end_date,
          funding_days,
          learned_about,
          ...rest
        } = item;
        return {
          ...rest,
          url: `${config.REACT_APP_URL}project/${item?.url}`,
        };
      });
      if (data?.length > 0) {
        this.handleDownloadCSV(data);
      }
    } catch (error) {}
  }
  onClickDropdown(v) {
    const { page, status, filter } = this.state;
    const filterValue = v;
    this.setState({ filter: filterValue });
    this.props.getAllProjects(perPage, page, status, filterValue);
  }
  render() {
    const { projects, loading, getAllProjects } = this.props;
    const { filter, isEnabled, isRecurring } = this.state;
    const columns = [
      {
        title: 'Project name',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      { title: 'goal', dataIndex: 'goal', key: 'goal' },
      { title: 'city', dataIndex: 'city', key: 'city' },
      {
        title: 'View',
        dataIndex: 'view',
        key: 'view',
        render: (text, record) => (
          <Link to={`/admin/view-project/${record.id}`}>View {record.id}</Link>
        ),
      },
      {
        title: 'Donations',
        dataIndex: 'donations',
        key: 'donations',
        render: (text, record) => (
          <Link to={`/admin/view-donation-detail/${record.id}`}>
            View Donation
          </Link>
        ),
      },
    ];
    return (
      <Layout>
        <Container>
          <Filter>
            <PrimaryButton
              onClick={() => {
                this.setState({
                  status: null,
                  page: 1,
                });
                getAllProjects(perPage, 1);
              }}
            >
              All
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                this.setState({
                  status: 'accepted',
                  page: 1,
                });
                getAllProjects(perPage, 1, 'accepted', filter);
              }}
            >
              accepted
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                this.setState({
                  status: 'pending',
                  page: 1,
                });
                getAllProjects(perPage, 1, 'pending', filter);
              }}
            >
              pending
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                this.setState({
                  status: 'declined',
                  page: 1,
                });
                getAllProjects(perPage, 1, 'declined', filter);
              }}
            >
              declined
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                this.setState({
                  status: 'finished',
                  page: 1,
                });
                getAllProjects(perPage, 1, 'finished', filter);
              }}
            >
              Finished
            </PrimaryButton>
          </Filter>
          <div
            className="flex-row"
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {/* <FormItem
          style={{ width:"30%"}}
                  name={'selectedProject2'}
                >
                  <Select
                  onChange={this.onClickDropdown}
                  defaultValue={"Filtertage"}
                  >
                    {durationArr &&
                      durationArr.map((project) => (
                        <Option
                          key={`${JSON.stringify(project.value)}`}
                          value={project.value}
                        >
                          {project.title}
                        </Option>
                      ))}
                  </Select>
                </FormItem> */}
            <div>
              {/* getDonationList */}
              {/* <PrimaryButton onClick={() => this.handleDownloadCSV()}> */}
              <PrimaryButton onClick={() => this.getAllProjectCsv()}>
                Download CSV
              </PrimaryButton>
              <PrimaryButton
                className={`${isEnabled ? 'true' : 'false'}`}
                onClick={this.handleClick}
                style={{ marginLeft: '81px' }}
              >
                {isEnabled ? 'Paypal Enabled' : 'Paypal Disabled'}
              </PrimaryButton>

              <PrimaryButton
                className={`${isRecurring ? 'true' : 'false'}`}
                onClick={this.handleClick1}
                style={{ marginLeft: '81px' }}
              >
                {isRecurring ? 'Recurring Enabled' : 'Recurring Disabled'}
              </PrimaryButton>
            </div>
          </div>

          <TableWrapper>
            <Table
              columns={columns}
              dataSource={projects.projects}
              loading={loading}
              pagination={{
                total: projects.count,
                pageSize: perPage,
                hideOnSinglePage: true,
                current: this.state.page,
                onChange: (page) => {
                  this.setState({ page });
                  getAllProjects(perPage, page, this.state.status);
                },
              }}
            />
          </TableWrapper>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  const { projects, loading } = state.admin;
  return {
    projects,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllProjects: (perPage, page, status = null, filter) =>
    dispatch(AdminActions.getAllProjects(perPage, page, status, filter)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllProjects);
