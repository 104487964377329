import React, { Component } from 'react';
import { Card } from 'components';
import styled from '@emotion/styled';
import { Colors, device, SvgWrapper } from 'utils';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectsActions } from 'redux/actions';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { SadFace } from 'images';
import ExternalLink from 'components/ExternalLink/ExternalLink';

const SupportProjectWrapper = styled.div`
  margin-bottom: 35px;
  .link {
    text-align: center;
    a {
      background: ${Colors.secondaryColor};
      border: 1px solid ${Colors.secondaryColor};
      color: ${Colors.white};
      text-align: center;
      border-radius: 12px;
      cursor: pointer;
      margin-bottom: 20px;
      padding: 15px 20px;
      min-width: 200px;
      font-size: 18px;

      &:hover {
        background: ${Colors.transparent};
        color: ${Colors.secondaryColor};
      }
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
  }
`;
const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  width: 100%;
  margin-bottom: 20px;
  .card {
    width: 32%;
    display: inline-block;
    text-align: center;
    margin-bottom: 14px;
    @media ${device.allMobile} {
      width: 100%;
      margin: auto;
      margin-bottom: 20px;
    }
    @media (max-width: 1340px) {
      width: 48%;
    }
    @media (max-width: 1140px) {
      width: 100%;
    }
    @media (max-width: 1025px) {
      width: 48%;
    }
    @media (max-width: 600px) {
      width: 100%;
    }
  }
`;

const SadFaceWrapper = styled.div`
  text-align: center;
  margin: 25px auto;
`;
class SupportProject extends Component {
  componentDidMount() {
    const { getMySupportedProjects } = this.props;
    getMySupportedProjects();
  }
  static propTypes = {
    projects: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
    getMySupportedProjects: PropTypes.func.isRequired,
  };

  render() {
    const { loading, projects } = this.props;

    return (
      <SupportProjectWrapper>
        <h2 id="supported-projects">Deine unterstützten Projekte:</h2>
        <Spin spinning={loading}>
          {projects && projects.length > 0 ? (
            <CardsWrapper>
              {projects.map((project) => (
                project.name !== 'ramadan2025' && <Card project={project} key={JSON.stringify(project)} />
              ))}
            </CardsWrapper>
          ) : (
            <SadFaceWrapper>
              <SvgWrapper width={'150px'} height={'auto'}>
                <SadFace />
              </SvgWrapper>
            </SadFaceWrapper>
          )}
        </Spin>
        <div className="link">
          <ExternalLink href="/projects">Jetzt neue Projekte entdecken</ExternalLink>
        </div>
      </SupportProjectWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { supportedProjects: projects, loading, errors } = state.projects;
  return {
    projects,
    loading,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMySupportedProjects: () =>
    dispatch(ProjectsActions.getMySupportedProjects()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportProject);
