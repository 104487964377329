import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { min } from 'moment/moment';
import { SupportImage } from 'images';
import { numberWithCommas } from 'utils';

const TipSelectorWrapper = styled.div`
  .tip-info-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 24px;
    font: Helvetica;
  }

  .tip-info-title {
    font-weight: bold;
    font-size: 16px;
  }

  .tip-info-text {
    font-size: 16px;
    color: #646464;
  }

  /* Style the slider container */
  .tip-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background: #e0e0e0;
    outline: none;
    opacity: 0.9;
    transition: opacity 0.2s;
    margin-top: 12px;
  }

  .tip-slider:hover {
    opacity: 1;
  }

  /* Dynamic filled portion before the thumb */
  .tip-slider::-webkit-slider-runnable-track {
    height: 8px;
    border-radius: 4px;
    background: linear-gradient(to right, #9DBC60 var(--progress), #e0e0e0 var(--progress));
  }

  .tip-slider::-moz-range-progress {
    background: #9DBC60;
  }

  /* Tick Marks */
  .tip-slider-ticks {
    position: absolute;
    padding-left: 11px;
    padding-right: 15px;
    top: 50%;
    left: 0;
    width: 100%;
    height: 12px;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    transform: translateY(-50%);
    margin-top: 6px;
  }

  .tip-slider-ticks span {
    width: 4px;
    height: 4px;
    background-color: white;
    border-radius: 50%;
    position: relative;
    margin-top: 4px;
  }

  .tip-slider-ticks span.multiple-of-5 {
    width: 2px;
    height: 8px;
    background-color: white;
    border-radius: 0;
    margin-top: 2px
  }

  /* Custom slider thumb */
  .tip-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border: 2px solid #9DBC60;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    margin-top: -8px;
  }

  .tip-slider::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    border: 2px solid #9DBC60;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    margin-top: -8px;
  }

  /* Tooltip for the slider thumb */
  .tip-slider-tooltip {
    position: absolute;
    background: white;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    display: block;
    margin-top: 16px;
  }

  .tip-slider-container {
    width: 100%;
    position: relative;
    display: inline-block;
  }

  /* Amount Display */
  .tip-amount {
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
    display: flex;
    align-items: center;
  }

  /* Input field styling */
  .tip-title {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 8px;
  }
  .tip-input-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    height: 52px;
    background-color: #ffffff;
  }
  .tip-input {
    flex: 1;
    border: none;
    outline: none;
    text-align: left;
    font-size: 18px;
    padding-left: 20px;
  }
  .tip-symbol {
    font-size: 18px;
    padding-right: 20px;
  }

  /* Button styling */
  .tip-button {
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }

  .tip-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .support-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #DCDCDC;
    border-radius: 10px;
    padding: 8px;
    margin-top: 20px;
    margin-bottom: 24px;
    align-items: center;
    background-color: #F5FBEE;
  }
    

  .support-image {
    width: 100%;
    max-width: 130px;
    max-height: 130px;
    align: justify;
  }

  .support-text {
    width: 100%;
    margin-bottom: 10px;
    color: #646464;
    font-size: 16px;
    text-align: center;
  }

  .support-link {
    font-size: 16px;
    color: #93B158;
    text-decoration: underline;
    text-align: center;
  }

  .support-link:hover {
    text-decoration: underline;
  }

  /* Modal styling */
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: auto;
  }

  .close {
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
  }

  .modal-text {
    max-height: 300px; /* Max height for the paragraph */
    overflow-y: auto; /* Scrollable if the text exceeds the max height */
  }

  .modal-text::-webkit-scrollbar {
    width: 8px; /* Adjust width as needed */
  }

  .modal-text::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light grey background */
    border-radius: 4px;
  }

  .modal-text::-webkit-scrollbar-thumb {
    background: #888; /* Darker grey scrollbar */
    border-radius: 4px;
  }

  .modal-text::-webkit-scrollbar-thumb:hover {
    background: #555; /* Even darker on hover */
  }
`;

const TipSelector = ({ usedDonation, setFreiwilligesState, defaultTip }) => {
  const [showInput, setShowInput] = useState(false);
  const [tipPercentage, setTipPercentage] = useState(defaultTip * 100 / usedDonation);
  const [progress, setProgress] = useState(50);
  const [customTip, setCustomTip] = useState();

  const [showDetailsBox, setShowDetailsBox] = useState(false); // Condition to display the box
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state

  const minSliderPercentage = 5;
  const maxSliderPercentage = 35;

  document.documentElement.style.setProperty("--progress", `${progress}%`);

  useEffect(() => {
    if (showInput) {
      setCustomTip(defaultTip);
      setFreiwilligesState(defaultTip);
    } else {
      const newPercentageValue = Math.floor(defaultTip * 100 / usedDonation);
      setFreiwilligesState((usedDonation * newPercentageValue / 100).toFixed(2));
      const newProgress = (newPercentageValue - minSliderPercentage) / (maxSliderPercentage - minSliderPercentage) * 100;
      setTipPercentage(newPercentageValue);
      setSliderProgressColor(newProgress);
      setSliderTooltipPosition(document.querySelector(".tip-slider").offsetWidth, newProgress);
    }
    
  }, [usedDonation]);

  const handleSliderChange = (event) => {
    const percentage = parseFloat(event.target.value);
    setTipPercentage(percentage);
    setFreiwilligesState((usedDonation * percentage) / 100);

    if (percentage === 5) {
      setShowDetailsBox(true);
    } else {
      setShowDetailsBox(false);
    }

    const newProgress = ((percentage - minSliderPercentage) / (maxSliderPercentage - minSliderPercentage)) * 100;
    setSliderProgressColor(newProgress);
    setSliderTooltipPosition(event.target.offsetWidth, newProgress);
  };

  const setSliderProgressColor = (newProgress) => {
    setProgress(newProgress);
    document.documentElement.style.setProperty("--progress", `${newProgress}%`);
  }

  const setSliderTooltipPosition = (offsetWidth, newProgress) => {
    const thumbWidth = 24; // Thumb size in px
    const sliderWidth = offsetWidth - thumbWidth; // Adjusted width
    const newThumbPosition = (newProgress / 100) * sliderWidth + thumbWidth / 2;
  
    const tooltip = document.getElementById("tooltip");
    tooltip.style.left = `${newThumbPosition}px`;
    console.log(sliderWidth);
    console.log(newThumbPosition);
  }

  const handleInputChange = (event) => {
    let value = event.target.value;

    value = value.replace(/[^0-9]/g, "");

    if (Number(value) === 0) {
        setShowDetailsBox(true);
        setFreiwilligesState(0);
    } else {
        setShowDetailsBox(false);
        setFreiwilligesState(Number(value));
    }
    setCustomTip(value);
  };

  const handleKeyDown = (event) => {
    if (!/^[0-9]$/.test(event.key) && event.key !== "Backspace") {
        event.preventDefault();
    }
  };

  const handleShowInput = () => {
    setShowInput(true);
    const calculatedTip = ((usedDonation * tipPercentage) / 100).toString();
    setCustomTip(calculatedTip);
    if (calculatedTip === "" || calculatedTip === "0") {
      setShowDetailsBox(true);
    } else {
      setShowDetailsBox(false);
    }
  };

  const handleShowSlider = () => {
    let calculatedPercentage = Math.ceil((customTip * 100) / usedDonation);
    if (calculatedPercentage < 5) calculatedPercentage = 5;
    if (calculatedPercentage > 35) calculatedPercentage = 35;
    setTipPercentage(calculatedPercentage);
    setFreiwilligesState((usedDonation * calculatedPercentage) / 100)
    setShowInput(false);

    const newProgress = ((calculatedPercentage - minSliderPercentage) / (maxSliderPercentage - minSliderPercentage)) * 100;
    setSliderProgressColor(newProgress);
    setSliderTooltipPosition(document.querySelector(".tip-slider").offsetWidth, newProgress);
    setProgress(newProgress);
    if (calculatedPercentage === 5) {
      setShowDetailsBox(true);
    } else {
      setShowDetailsBox(false);
    }
  };

  useEffect(() => {
    if (!showInput) {
      setSliderTooltipPosition(document.querySelector(".tip-slider").offsetWidth, progress);
    }
  }, [showInput]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  const handleOpenModal = (event) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <TipSelectorWrapper>
        <div className="tip-info-container">
          <span className="tip-info-title">Freiwilliger Beitrag für commonsplace 💚</span>
          <span className="tip-info-text">Wir helfen der deutsch-muslimischen Community, ihre Werte selbstorganisiert zu leben und die Gesellschaft mitzugestalten. Damit commonsplace unabhängig wächst, brauchen wir deine Unterstützung.</span>
        </div>
        {!showInput ? (
          <>
            <div className="tip-slider-container">
              <input
                type="range"
                min={minSliderPercentage}
                max={maxSliderPercentage}
                step="1"
                value={tipPercentage}
                onChange={handleSliderChange}
                className="tip-slider"
              />
              <div id="tooltip" className="tip-slider-tooltip">
                <span style={{ fontWeight: "bold" }}>
                  {numberWithCommas((usedDonation * tipPercentage) / 100)} €
                </span> 
                {' '}({tipPercentage} %)
              </div>
              <div className="tip-slider-ticks">
                {[...Array(maxSliderPercentage - minSliderPercentage + 1)].map((_, index) => {
                  const value = index + minSliderPercentage;
                  return (
                    <span
                      key={value}
                      className={value % 5 === 0 ? "multiple-of-5" : ""}
                      style={{ left: `${index / (maxSliderPercentage - minSliderPercentage)}%` }}
                    ></span>
                  );
                })}
              </div>
            </div>
            <div class="tip-button-container">
              <button onClick={handleShowInput} className="tip-button">
                Eigener Betrag
              </button>
            </div>
          </>
        ) : (
          <>
            <label class="tip-title">Eigener Betrag</label>
            <div class="tip-input-container">
              <input
                type="number"
                value={customTip}
                placeholder="0"
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="tip-input"
              />
              <span class="tip-symbol">€</span>
            </div>
            <div class="tip-button-container">
              <button onClick={handleShowSlider} className="tip-button">
                Zurück zur Standardeinstellung
              </button>
            </div>
          </>
        )}

        {/* Conditionally render the box */}
        {showDetailsBox && (
          <div className="support-container">
            <img src={SupportImage} alt='Description' className='support-image' />
            <span className="support-text">Mit deiner Hilfe vernetzt commonsplace nachhaltig Unterstützer mit wichtigen Projekten. So wächst die Community und entfaltet Wirkung. Ohne Beiträge bleibt die Vision begrenzt.</span>
            <a href="#" onClick={handleOpenModal} className="support-link">
              Warum unterstützen?
            </a>
          </div>
        )}

        {/* Modal (hidden by default) */}
        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <p className='modal-text'>Gemeinsam die deutsch-muslimische Community stärken! <br /> <br />
                  Unterstütze uns, um deine Community zu unterstützen! <br /> <br />
                  commonsplace ist mehr als nur eine Plattform – wir verbinden, fördern und ermöglichen muslimische Projekte in Deutschland. Mit der Kraft und Kreativität der Community unterstützen wir soziale Initiativen, Moscheeprojekte, Bildungsprojekte und muslimisches Unternehmertum. Unsere Arbeit macht muslimisches Engagement sichtbar und lässt die gesamte Gesellschaft davon profitieren. <br /> <br />
                  Auch wir sind auf die Unterstützung der Community angewiesen – commonsplace wird durch freiwillige Beiträge finanziert, ähnlich wie Wikipedia. <br /> <br />
                  Unser Wachstum ist ein gemeinsamer Verdienst, der es ermöglicht, die Plattform am Laufen zu halten und laufende Kosten für IT-Entwicklung, Server, Mitarbeiter, Dienstleister, Marketing, Wettbewerbe und Innovationen wie das MuslimLab zu decken. Ohne euch wäre das nicht möglich. <br /> <br />
                  Danke, dass du Teil dieser Vision bist!</p>
                  <button className="tip-button" onClick={handleCloseModal}>Schließen</button>
            </div>
          </div>
        )}
      </TipSelectorWrapper>
    </div>
  );
};

export default TipSelector;
