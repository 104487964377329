import { Footer, Header, PaymentCard } from 'components';
import config from 'config';
import React, { Component } from 'react';
import ContactModal from 'scenes/Project/components/ContactModal';
import { Container } from 'utils';
import mask_Img from '../../images/ramzan/maskgroup.svg'
import small_img from '../../images/ramzan/smallimage.svg'
import down_arrow from '../../images/ramzan/downarrow.svg'
import first from '../../images/ramzan/first.svg'
import second from '../../images/ramzan/second.svg'
import third from '../../images/ramzan/third.svg'
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import styles from './ramzan.module.css';
import { Redirect } from 'react-router-dom';
import DonationPage from 'scenes/Donation/DonationPage';
import { Colors, Modal, PrimaryButton, device, GreenButton, H1 } from 'utils';
import { Donation, InfoForm } from 'scenes/Donation/components';
import { Button, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';


const APP_URL = config.REACT_APP_URL;

const DonationFormWrapper = styled.div`
  //   display: flex;
  margin-top: 5px;
  @media ${device.allMobile} {
    flex-wrap: wrap;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .paymentCard {
    label {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .ant-row {
      display: block;
    }
    .payment-card {
      max-width: 100%;

      .ant-form-item-control-input-content {
        @media ${device.allMobile} {
          max-width: 200px;
        }
        div {
          max-width: 100%;
        }
        input {
          font-size: 18px;
        }
        #cardNumber {
          width: 300px;
        }
      }
    }
  }

  h1 {
    font-weight: bold;
  }
  .zahlungsmethode {
    h2 {
      font-size: 22px;
      font-weight: bold;
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
    & > div {
      display: flex;
      justify-content: space-around;
      font-size: 35px;
      margin-bottom: 20px;
      @media ${device.allMobile} {
        font-size: 22px;
      }
    }
  }
  .deine-label {
    input {
      border-radius: 15px;
      padding: 15px;
    }
  }
  .gesamtsumme-button {
    @media ${device.allMobile} {
      min-width: 100%;
    }
  }
  .gesamtsumme {
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 30px;
      font-weight: bold;
      margin: 0;
      @media ${device.allMobile} {
        font-size: 20px;
      }
    }
    p {
      font-size: 30px;
      font-weight: bold;
      margin: 0;
      @media ${device.allMobile} {
        font-size: 20px;
      }
    }
    & + p {
      font-size: 22px;
      margin-bottom: 30px;
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
  }
  .danke {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 500px;
    p {
      text-align: left;
      font-size: 22px;
      @media ${device.laptop} {
        font-size: 18px;
      }
      @media ${device.allMobile} {
        font-size: 16px;
      }
    }
  }
  & > div {
    width: 100%;
    // padding: 20px 0px;
    // padding-top: 20px;
    &:first-of-type {
      padding-left: 0;
    }
    &:nth-of-type(2) {
      padding-right: 0;
    }
    @media ${device.allMobile} {
      width: 100%;
    }
    @media ${device.allMobile} {
      padding: 0;
    }
    @media ${device.tablet} {
      margin-top: 0;
    }
    .ant-checkbox-wrapper {
      a {
        color: ${Colors.secondaryColor};
        margin: 0 3px;
        display: inline-block;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .freiwilliges-input {
      position: relative;
      .anticon {
        position: absolute;
        right: 150px;
        font-size: 22px;
        top: 50%;
        transform: translateY(-50%);
        @media ${device.allMobile} {
          font-size: 16px;
          right: 10px;
        }
      }
    }
    .donation-input {
      display: flex;
      padding: 20px 20px 20px 20px;
      border: 1px solid ${Colors.steel};
      border-radius: 10px;
      justify-content: space-between;
      align-items: center;
      @media ${device.allMobile} {
        padding: 10px;
      }
      input,
      .ant-input-group-addon {
        border: 0;
        background: transparent;
        font-weight: bold;
        font-size: 22px;
        @media ${device.allMobile} {
          font-size: 16px;
        }
      }

      .ant-form-item-control {
        text-align: right;
        @media ${device.tablet} {
          max-width: 97px;
        }
        @media ${device.allMobile} {
          max-width: 55%;
        }
        input {
          font-weight: bold;
          font-size: 22px;
          padding-right: 10px;
          text-align: right;
          max-width: 100%;
          @media ${device.allMobile} {
            font-size: 16px;
            // max-width: 150px;
          }
          @media ${device.tablet} {
            font-size: 16px;
            max-width: 104px;
          }
        }
        .ant-input-group-addon {
          padding: 0;
          text-align: left;
        }
      }
      .ant-form-item-label {
        width: 40%;
        display: inline-block;
        text-align: left;
        padding: 0;
        @media ${device.tablet} {
          width: 60%;
        }
        @media ${device.allMobile} {
          width: 45%;
          max-width: 45%;
        }
        label {
          font-size: 22px;
          font-weight: bold;
          @media ${device.allMobile} {
            font-size: 16px;
            line-height: 1;
          }
          &:before,
          &:after {
            content: none;
          }
        }
      }
    }
  }

  .show-options {
    .donation-input {
      .ant-form-item-label {
        width: 35%;
        @media ${device.laptop} {
          width: 31%;
          max-width: 31%;
        }
        @media ${device.allMobile} {
          width: 120px;
          max-width: 120px;
        }
        @media ${device.tablet} {
          width: 35%;
          max-width: 35%;
        }
      }
      .ant-form-item-control {
        max-width: 80%;
        @media ${device.laptop} {
          width: 69%;
          max-width: 69%;
        }
        @media ${device.tablet} {
          width: 65%;
          max-width: 65%;
        }
        @media ${device.allMobile} {
          width: fit-content;
          max-width: fit-content;
          margin-top: 10px;
        }
      }
    }
    .freiwilliges-option {
      @media ${device.allMobile} {
        display: flex;
        justify-content: flex-end;
        max-width: 200px;
        margin-left: auto;
        overflow: scroll;
      }

      div {
        display: inline-block;
        button {
          margin: 0;
          @media ${device.allMobile} {
            padding: 5px 5px;
            font-size: 12px;
            border-radius: 5px;
            min-width: 36px;
          }
        }
        & + div {
          margin-left: 15px;
          @media ${device.tablet} {
            margin-left: 10px;
          }
          @media ${device.allMobile} {
            margin-left: 10px;
          }
        }
      }
    }
  }
`;

const HeroContainer = styled.div`
        position: relative;
        text-align: center;
        width: 100%;
`
const StyledHeroImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;

  @media screen and (max-width: 425px) {
    content: url(${small_img});
  }
`;

const HeroTitle = styled.div`

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: clamp(24px, 8vw, 48px);
        font-weight: 800;
        width: 90%;
        text-align: center;
      
      @media screen and (max-width: 768px) {
          font-size: 36px;
      }

      @media screen and (max-width: 425px) {
          font-size: 30px;
          font-weight: 800;
      }
    `
const ArrowIcon = styled.img`
        width: 20px;
        height: 20px;  
`
const AncharTag = styled.button`
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #dab44d;
        color: white;
        padding: 10px 20px;
        font-size: clamp(16px, 5vw, 18px);
        font-weight: 700;
        border-radius: 5px;
        text-decoration: none;
        white-space: nowrap;
        min-width: 235px;
        display: flex;
        align-items: center;
        gap: 8px;

      @media screen and (max-width: 1024px) {
      
          bottom: 15px;
      }

      @media screen and (max-width: 768px) {
          bottom: 10px;
      }
`

const location = {path: '/project/:id/donation', url: '/project/null/donation', isExact: true, params: {id: 'null'}, pathname: "/project/:id/donation", url: "/project/null/donation"}
const match ={path: '/project/:id/donation', url: '/project/null/donation', isExact: true, params: {id: 'null'}}

class Ramzan extends Component {
    constructor(props) {
        super(props);
        this.state = {
          project_id: null,
          recurring_check: false,
          isLoading: true,
          error: null,
          showModal: false,
          showPaymentForm: false,
          currentView: null,
          showOption: false,
          otherOption: false,
          donation: parseFloat(
            5
          ).toFixed(2),
          freiwilliges: 0,
          allRewards: [],
          selectedRewards: [],
          addressRequired: false,
          clientSecret: null,
        };
      }

        donationFormRef = React.createRef();
      componentDidMount() {
        const requestOptions = {
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify({ project_name: "ramadan2025" }), 
          };
          return fetch(`find_ramadan`, requestOptions)
            .then((response ) => {
                this.setState({project_id: response.id, recurring_check: response.recurring_check})
              return { response };
            })
            .catch((error) => {
              return Promise.reject({ errors: error });
            });
      }
    
      handleCLientSecret = (value) => {
        this.setState({ clientSecret: value });
      };
      handelCloseModal = () => {
        this.setState({ showModal: false });
        document.body.style.overflowY = 'unset';
      };
    
      handelShowModal = () => {
        this.setState({ showModal: true });
        document.body.style.overflowY = 'hidden';
      };
      setDonationState = (donation) => {
        this.setState({ donation });
      };
      setCurrentViewState = (currentView) => {
        this.setState({ currentView });
      };
      setShowOptionState = (showOption) => {
        this.setState({ showOption });
      };
      setOtherOptionState = (otherOption) => {
        this.setState({ otherOption });
      };
      setFreiwilligesState = (freiwilliges) => {
        this.setState({ freiwilliges });
      };
      setShowPaymentFormState = (showPaymentForm) => {
        this.setState({ showPaymentForm });
      };
      setAllRewards = (allRewards) => {
        this.setState({ allRewards });
      };
      setSelectedRewards = (selectedRewards) => {
        this.setState({ selectedRewards });
      };
      setAddressRequired = (selectedRewards) => {
        let isAddressRequired = false;
        selectedRewards.forEach((reward) => {
          isAddressRequired = isAddressRequired || reward.address_required;
        });
        this.setState({
          addressRequired: isAddressRequired,
        });
      };
  render() { 
    const {
      showPaymentForm,
      donation,
      freiwilliges,
      showOption,
      otherOption,
      currentView,
      showModal,
    } = this.state;
    const {
      location: { search, state },
      donationSuccess,
      match: {
        params: { id },
      },
    } = this.props;
    const project = {url: this.state.project_id, title: 'Ramdan2025'}
    const handleScroll = () => {
      const element = document.getElementById('payment_card');
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const handleScrollToInfo = () => {
      const element = document.getElementById('info_Card');
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const originalDonation = Number(
      new URLSearchParams(search).get('donation') || 5
    );
    const originalRewardId = Number(
      new URLSearchParams(search).get('rewardId') || 0
    );
    return (
      <>
        <ContactModal />
        <div>
          <Header relativeHeader={true} />
            <HeroContainer >
      <picture>
        <source
          media="(max-width: 425px)"
          srcset={small_img}
        />
        <StyledHeroImage
          src={mask_Img}
          alt="Commons Place Logo"
        />
      </picture>

      <HeroTitle>Deine tägliche Ramadan - Spende</HeroTitle>

      <AncharTag
      onClick={handleScrollToInfo}
      style={{color: 'white', border: 'none'}}>
        <ArrowIcon
          src={down_arrow}
          alt="Down Arrow"
        />
        Sadaqah einrichten
      </AncharTag>
    </HeroContainer>
    <Container>
    <div className={styles.content_container}>
      <div className={styles.corner_top_left}></div>
      <div className={styles.corner_bottom_right}></div>

      <h2 className={styles.main_heading}>Jeden Tag Gutes tun!</h2>
      <p className={styles.main_description}>
        Bleib fokussiert auf deine Ibadah und lass deine Unterstützungen Großes bewirken - jeden Tag!
      </p>

      <div style={{ maxWidth: '600px', margin: '2rem auto', padding: '0 20px' }} id='info_Card' >
        <div className={styles.step_container}>
          <img src={first} alt="First step" className={styles.step_icon} />
          <div>
            <h2 className={styles.step_heading}>Wähle deinen Betrag:</h2>
            <p className={styles.step_description}>
              Entscheide selbst, wie viel du täglich spenden möchtest.
              <Tooltip
                            title={
                              <span style={{ color: '#fff' }}>
                                Die Aktion läuft bis zum letzten Tag des Ramadan und endet danach 
                                automatisch. Stornierungen sind vorab per E-Mail an salam@commonsplace.de 
                                möglich.
                              </span>
                            }
                            color="#95bd51"
                            overlayClassName="custom-tooltip"
                          >
                            <Button type="link" style={{ padding: '0px 2px' }}>
                              <InfoCircleOutlined
                                style={{ color: 'black', fontSize: '20px' }}
                              />
                            </Button>
                          </Tooltip>
          {/* <img src={vector} alt="Vector step" className={styles.vector_icon} /> */}
            </p>
          </div>
        </div>

        <div className={styles.step_container}>
          <img src={second} alt="Second step" className={styles.step_icon} />
          <div>
            <h2 className={styles.step_heading}>Vielfältige Wirkung:</h2>
            <p className={styles.step_description}>
              Deine Spende unterstüzt über 200 Projekte, darunter Moscheen und Bildungsinitiativen.
            </p>
          </div>
        </div>

        <div className={styles.step_container}>
          <img src={third} alt="Third step" className={styles.step_icon} />
          <div>
            <h2 className={styles.step_heading}>Alles im Blick:</h2>
            <p className={styles.step_description}>
              Wöchentliche Updates zeigen dir, wie dein Beitrag wirkt.
            </p>
          </div>
        </div>
      </div>

      <div className={styles.button_container}>
        <button
        onClick={handleScroll}
          style={{color: "white"}}
        className={styles.bottom_cta}>
          <ArrowIcon src={down_arrow} alt="Down Arrow" className={styles.arrow_icon} />
          Jetzt unterstützen!
        </button>
      </div>
    </div>
         {this.state.project_id && <div className="inner_div" id='payment_card'>
          <DonationFormWrapper>
                        <Donation
                          showPaymentForm={showPaymentForm}
                          showOption={showOption}
                          otherOption={otherOption}
                          originalDonation={originalDonation}
                          donation={donation}
                          freiwilliges={freiwilliges}
                          setDonationState={this.setDonationState}
                          setCurrentViewState={this.setCurrentViewState}
                          setShowOptionState={this.setShowOptionState}
                          setOtherOptionState={this.setOtherOptionState}
                          setFreiwilligesState={this.setFreiwilligesState}
                          setShowPaymentFormState={this.setShowPaymentFormState}
                          projectId={this.state.project_id}
                          recurringCheck={this.state.recurring_check}
                          isTicketable={project?.is_ticketable}
                          originalRewardId={originalRewardId}
                          setAllRewards={this.setAllRewards}
                          setSelectedRewards={this.setSelectedRewards}
                          allRewards={this.state.allRewards}
                          selectedRewards={this.state.selectedRewards}
                          formRef={this.donationFormRef}
                          setAddressRequired={this.setAddressRequired}
                          handleCLientSecret={this.handleCLientSecret}
                          handelShowModal={this.handelShowModal}
                          project={project}
                          project_name={'ramadan2025'}
                        />
        
                        <InfoForm
                          setCurrentViewState={this.setCurrentViewState}
                          amount={Number(donation)}
                          tips={Number(freiwilliges)}
                          project={project}
                          projectId={this.state.project_id}
                          donationFormRef={this.donationFormRef}
                          selectedRewards={this.state.selectedRewards}
                          addressRequired={this.state.addressRequired}
                          clientSecret={this.state.clientSecret}
                          handleCLientSecret={this.handleCLientSecret}
                          // handleCLientSecret={handleCLientSecret}
                        />
        
                        {showPaymentForm && currentView === 'payment' && (
                          <div className="paymentCard zahlungsmethode">
                            <PaymentCard />
                          </div>
                        )}
                      </DonationFormWrapper>
                      </div>}
          </Container>
          <Footer />
        </div>
      </>
    );
  }
}

export default Ramzan;